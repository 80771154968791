import axios from "axios";
import ColabBody from "../Componentes/ColabBody";
import NavBar from "../Componentes/NavBar";
import Overlay from "../Componentes/Overlay";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";
import BenefitsList from "../Componentes/BenefitsList";
import NewFolga from "../Componentes/NewFolga";

import { useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../Auth/AuthProvider";

const Colab = () => {

    const { user } = useContext( AuthContext )

    const permissions = user.PERMISSIONS
    const username = user.USER

    const [ sidebarOverlay, setSidebarOverlay] = useState( false );
    const [ benefitOverlay, setBenefitOverlay] = useState( false );
    const [ folgaOverlay, setFolgaOverlay ] = useState( false );

    let { matricula } = useParams()

    const [colab, setColab] = useState([])
    const [loadingColab, setLoadingColab] = useState(true)

    const getColabInfo = async () => {

        try {

            //const { data } = await axios.post("http://localhost/Painel/API/GetColabInfo/", {
            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetColabInfo/', {
                matricula: matricula
            })

            console.log(data);

            setColab(data[0])

        } catch (error) {

            alert(error.message);

        } finally {

            setLoadingColab(false);

        }

    }

    useEffect(() => {

        getColabInfo();

    }, [])

    const openSideBar = () => {

        setSidebarOverlay( true );

    }

    const closeSideBar = () => {

        setSidebarOverlay( false );

    }

    const openBenefits = () => {

        setSidebarOverlay( false );
        setBenefitOverlay( true );

    }

    const closeBenefits = () => {

        setBenefitOverlay( false );

    }

    const openFolgas = () => {

        setSidebarOverlay( false );
        setFolgaOverlay( true );

    }

    const closeNewFolga = () => {

        setFolgaOverlay( false );

    }

    return (
        <>
            <NavBar
                setSideBar={ openSideBar }
                title="COLABORADOR"
            />
            <ColabBody
                colabInfo={colab}
                loading={loadingColab}
                userID={username}
                matricula={matricula}
            />

            <Overlay
                state={ sidebarOverlay }
                changeContent={ closeSideBar }
            >

                <SideMenu>
                    {permissions.includes('can_see_benefits') && (
                        <SideMenuBtn
                            button="BENEFÍCIOS"
                            clickFunc={() => openBenefits() }
                        />
                    )}

                    <SideMenuBtn
                        button="FOLGA DE CAMPO"
                        clickFunc={() => openFolgas() }
                    />


                </SideMenu>

            </Overlay>

            <Overlay
                state={ benefitOverlay }
                changeContent={ closeBenefits }
            >
                <BenefitsList
                    obraColab={ colab.CENTRO_CUSTO }
                />
            </Overlay>

            <Overlay
                state={ folgaOverlay }
                changeContent={ closeNewFolga }
            >
                <NewFolga
                    municipio={ colab.MUNICIPIO }
                    estado={ colab.ESTADO }
                    obra={ colab.CENTRO_CUSTO }
                    user={ user.USER }
                    closeFolga={ closeNewFolga }
                />
            </Overlay>

        </>
    );

}

export default Colab;