import FormComp from '../../FormComp';
import SmallLoading from "../../../Componentes/Utils/SmallLoading";
import style from './FormTreinamentos.module.css';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Overlay from '../../Overlay';
import { formatarData } from '../../../Funcoes/FormatarData'

const FormTreinamentos = ({ closeFunc, updateTreinamentos }) => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [confirm, setConfirm] = useState(false)

    const [formValues, setFormValues] = useState({
        centro_custo: '',
        contrato: '',
        motivo: '',
        tipo: '',
        treinamento: '',
        data_inicio: '',
        data_fim: '',
        processo: '',
        local: '',
        validade: '',
        carga: '',
        instrutor: '',
        colaboradores: []
    })

    const [obras, setObras] = useState([])
    const [contratosObras, setContratosObras] = useState([])
    const [contratos, setContratos] = useState([])
    const [treinamentosValues, setTreinamentosValues] = useState([])
    const [treinamentosInfo, setTreinamentosInfo] = useState([])
    const [instrutores, setInstrutores] = useState([])
    const [colabordadores, setColaboradores] = useState([])


    useEffect(() => {

        const fetchData = async () => {

            setLoading(true);

            try {

                const [obrasRes, contratosRes, treinamentosRes, treinamentosList, instrutoresRes, colabsRes, formValuesRes] = await Promise.all([

                    axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasAtivas/'),
                    axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetContratosAtivos/'),
                    axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GetTreinamentosValues/'),
                    axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GetTreinamentosList/'),
                    axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GetInstrutores/'),
                    id ? Promise.resolve({ data: [] }) : axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GetColabs/'),
                    id ? axios.get(`https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GetTreinamentoById/?id=${id}`) : Promise.resolve({ data: {} })

                ])

                setObras(obrasRes.data.map(obj => obj.OBRA))
                setContratosObras(contratosRes.data)
                setTreinamentosInfo(treinamentosRes.data)
                setTreinamentosValues( treinamentosList.data.map( obj => obj.treinamento ) )
                setInstrutores(instrutoresRes.data.map(obj => ({ value: obj.identifier, label: obj.nome })))

                if (!id) {

                    setColaboradores(colabsRes.data)

                } else {

                    console.log('editando treinamento especifico:')
                    console.log(formValuesRes.data)

                    const treinamento = formValuesRes.data

                    setFormValues({
                        centro_custo: treinamento.obra,
                        contrato: treinamento.contrato,
                        motivo: treinamento.motivo,
                        tipo: treinamento.tipo,
                        treinamento: treinamento.treinamento,
                        data_inicio: treinamento.data_inicio,
                        data_fim: treinamento.data_fim,
                        processo: treinamento.processo,
                        local: treinamento.local,
                        validade: treinamento.validade,
                        carga: treinamento.carga_horaria,
                        instrutor: treinamento.instrutor,
                        colaborador: treinamento.nome
                    })

                }

            } catch (error) {

                console.log(error)

            } finally {

                setLoading(false)

            }

        }

        fetchData();

    }, [id]);


    const handleChange = (field, e) => {

        let { value } = e.target;

        setFormValues(prev => ({
            ...prev,
            [field]: value,
            ...(field === 'centro_custo' && { contrato: '' })
        }))

    }

    useEffect(() => {

        console.log(formValues)

    }, [formValues])

    useEffect(() => {

        if (formValues.centro_custo) {

            const contratosByObra = contratosObras.filter(obj => obj.centro_custo === formValues.centro_custo)
            setContratos(contratosByObra.map(obj => obj.contrato))

        } else {

            setContratos([])

        }

    }, [formValues.centro_custo])

    useEffect(() => {

        if (formValues.tipo && formValues.treinamento && !id) {

            const info = treinamentosInfo.find(obj => obj.treinamento === formValues.treinamento && obj.tipo_treinamento === formValues.tipo)

            if( info ){

                setFormValues(prev => ({

                    ...prev,
                    validade: info.validade,
                    carga: info.duracao
    
                }))

            }else{

                setFormValues( prev => ({
                    ...prev,
                    validade: '',
                    carga: ''
                }))

            }

        }

    }, [formValues.tipo, formValues.treinamento, treinamentosInfo])

    useEffect(() => {

        if (formValues.data_inicio && formValues.carga) {

            let dias = Math.floor(formValues.carga / 8);

            if (formValues.carga % 8 === 0) {

                dias -= 1

            }

            const dataInicio = new Date(formValues.data_inicio)

            if (!isNaN(dataInicio)) {

                const dataFim = new Date(dataInicio)
                dataFim.setDate(dataFim.getDate() + dias)

                const dataFimFormat = dataFim.toISOString().split('T')[0];

                setFormValues(prev => ({

                    ...prev,
                    data_fim: dataFimFormat

                }))

            }

        }

    }, [formValues.data_inicio, formValues.carga])

    const [colabsChecked, setColabsChecked] = useState([])

    const handleSubmit = async (e) => {

        e.preventDefault();

        setSaving(true);

        try {

            const formData = new FormData();
            formData.append('treinamento', formValues.treinamento)

            let colabs = formValues.colaboradores.map((colab) => colab.value)
            colabs.forEach((valor, index) => {
                formData.append(`colaboradores[${index}]`, valor)
            })

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/CheckValidade/', formData, { withCredentials: true });
            setColabsChecked(data)

            if (data.length > 0) {

                setConfirm(true)

            } else {

                await saveTreinamentos()

            }

        } catch (error) {

            console.log(error)

        }

    }

    const closeConfirm = () => {

        setColabsChecked([])
        setConfirm(false)
        setSaving(false)

    }

    const saveTreinamentos = async () => {

        setConfirm(false);
        setColabsChecked([]);

        try {

            const formData = new FormData();

            Object.entries(formValues).forEach(([key, value]) => {
                formData.append(key, typeof value === "object" ? JSON.stringify(value) : value);
            });

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/SaveTreinamentos/', formData, { withCredentials: true });
            console.log(data);

        } catch (error) {

            console.log(error)

        } finally {

            await updateTreinamentos();
            setSaving(false);
            closeFunc();

        }

    }

    if (loading) {

        return (
            <SmallLoading />
        )

    }

    return (
        <>
            <Overlay state={saving} >
                {
                    confirm ? (
                        <div className={style.confirmContainer} >
                            <h3>OS SEGUINTES TREINAMNETOS AINDA POSSUEM VÁLIDADE, REALMENTE DESEJA SALVA-LOS ?</h3>
                            {
                                colabsChecked.map((colab) => {
                                    return (
                                        <p key={colab.cpf} >{colab.nome} VÁLIDO ATÉ {formatarData(colab.validade)}</p>
                                    )
                                })
                            }
                            <div className={style.containerBtns} >
                                <button className={style.cancelBtn} onClick={closeConfirm} >CANCELAR</button>
                                <button className={style.confirmBtn} onClick={saveTreinamentos} >CONFIRMAR</button>
                            </div>
                        </div>
                    ) : (
                        <div className={style.loadingDiv} >
                            <SmallLoading />
                        </div>
                    )
                }
            </ Overlay>
            <form onSubmit={handleSubmit} >
                <FormComp
                    title="CENTRO DE CUSTO"
                    type="select"
                    options={obras}
                    valor={formValues.centro_custo}
                    changeFunc={(e) => handleChange('centro_custo', e)}
                    disabled={id}
                />
                <FormComp
                    title="CONTRATO"
                    type="select"
                    options={contratos}
                    valor={formValues.contrato}
                    changeFunc={(e) => handleChange('contrato', e)}
                    disabled={id}
                />
                <FormComp
                    title="MOTIVO"
                    type="select"
                    options={[
                        "NOVO EMPREGADO",
                        "NOVAS RESPONSABILIDADES",
                        "COMUNICAÇÃO",
                        "QUALIFICAÇÃO",
                        "RECICLAGEM",
                        "PROCEDIMENTO/NORMA"
                    ]}
                    valor={formValues.motivo}
                    changeFunc={(e) => handleChange('motivo', e)}
                    disabled={id}
                />
                <FormComp
                    title="TIPO"
                    type="select"
                    options={[
                        "INICIAL",
                        "PERIÓDICO",
                        "EVENTUAL"
                    ]}
                    valor={formValues.tipo}
                    changeFunc={(e) => handleChange('tipo', e)}
                    disabled={id}
                />
                <FormComp
                    title="TREINAMENTO"
                    type="select"
                    options={treinamentosValues}
                    valor={formValues.treinamento}
                    changeFunc={(e) => handleChange('treinamento', e)}
                    disabled={id}
                />
                <FormComp
                    title="DATA DE ÍNICIO"
                    type="date"
                    valor={formValues.data_inicio}
                    changeFunc={(e) => handleChange('data_inicio', e)}
                    readOnly={id}
                />
                <FormComp
                    title="DATA TERMINO"
                    type="date"
                    valor={formValues.data_fim}
                    readOnly
                />
                <FormComp
                    title="PROCESSO"
                    type="select"
                    options={[
                        "MEIO-AMBIENTE",
                        "QUALIDADE",
                        "SAÚDE",
                        "SEGURANÇA"
                    ]}
                    valor={formValues.processo}
                    changeFunc={(e) => handleChange('processo', e)}
                    disabled={id}
                />
                <FormComp
                    title="LOCAL DO TREINAMENTO"
                    type="select"
                    options={obras}
                    valor={formValues.local}
                    changeFunc={(e) => handleChange('local', e)}
                    disabled={id}
                />
                <FormComp
                    title="VALIDADE ( MESES )"
                    type="number"
                    valor={formValues.validade}
                    changeFunc={(e) => handleChange('validade', e)}
                    readOnly={id}
                />
                <FormComp
                    title="CARGA HORÁRIA"
                    type="number"
                    valor={formValues.carga}
                    changeFunc={(e) => handleChange('carga', e)}
                    readOnly={id}
                />
                <FormComp
                    title="INSTRUTOR"
                    type="select"
                    obj
                    options={instrutores}
                    valor={formValues.instrutor}
                    changeFunc={(e) => handleChange('instrutor', e)}
                    disabled={id}
                />
                {
                    !id ? (
                        <FormComp
                            title="COLABORADORES"
                            type="multiselect"
                            options={
                                colabordadores.map(obj => ({
                                    value: obj.CPF,
                                    label: obj.NOME
                                }))
                            }
                            multiple
                            placeholder="SELECIONE..."
                            changeFunc={(selected) => setFormValues(prev => ({
                                ...prev,
                                colaboradores: selected || []
                            }))}
                        />
                    ) : (
                        <FormComp
                            title="COLABORADOR"
                            type="text"
                            valor={formValues.colaborador}
                            readOnly
                        />
                    )
                }

                {
                    formValues.colaboradores?.length > 0 && (
                        <div className={style.colabsContainer} >
                            {
                                formValues.colaboradores.map(colaborador => (
                                    <p key={colaborador.value}>{colaborador.label}</p>
                                ))
                            }
                        </div>
                    )
                }

                {
                    !id && (

                        <FormComp
                            type="submit"
                            valor="SALVAR"
                        />

                    )
                }

            </form>
        </>
    )

}

export default FormTreinamentos;