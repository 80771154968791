import { useEffect, useState } from 'react';
import FormComp from '../../FormComp';
import style from './FormProgram.module.css';
import axios from 'axios';
import SmallLoading from '../../Utils/SmallLoading';

const FormProgram = ({ closeFunc, updateDados }) => {

    const [loading, setLoading] = useState(true);
    const [ saving, setSaving] = useState(false);

    const [treinamentos, setTreinamentos] = useState([])
    const [obras, setObras] = useState([])
    const [contratosObra, setContratosObra] = useState([])
    const [contratos, setContratos] = useState([])
    const [ghes, setGhes] = useState([])

    useEffect(() => {

        const fetchData = async () => {

            try {

                const [treinamentos, obras, contratos, ghesVal] = await Promise.all([

                    axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GetTreinamentosList/'),
                    axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasAtivas/'),
                    axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetContratosAtivos/'),
                    axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/InfoPanel/GetValues/')

                ])

                setTreinamentos(treinamentos.data.map(obj => obj.treinamento))
                setObras(obras.data.map(obj => obj.OBRA))
                setContratosObra(contratos.data)
                setGhes( ghesVal.data.GHE );

            } catch (e) {

                console.error(e)

            } finally {

                setLoading(false)

            }

        }

        fetchData();

    }, [])

    const [formValues, setFormValues] = useState({
        treinamento: "",
        centroCusto: "",
        contrato: "",
        previsao: "",
        ghe: "",
        funcao: "",
        colaboradores: []
    })

    useEffect(() => {

        if (formValues.centroCusto) {

            setFormValues(prev => ({ ...prev, contrato: '' }))
            const contratosByObra = contratosObra.filter(obj => obj.centro_custo === formValues.centroCusto)
            setContratos(contratosByObra.map(obj => obj.contrato))

        } else {

            setContratos([])

        }

    }, [formValues.centroCusto])

    const handleChange = (field, e) => {

        setFormValues(prev => ({
            ...prev,
            [field]: e.target.value
        }))

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        setSaving( true )

        try {

            const formData = new FormData();
            formData.append('treinamento', formValues.treinamento);
            formData.append('centro_custo', formValues.centroCusto);
            formData.append('contrato', formValues.contrato);
            formData.append('previsao', formValues.previsao);

            await axios.post('http://localhost/Painel/API/Treinamentos/ProgramTreinamento/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            await updateDados();
            closeFunc();

        } catch (e) {

            console.log(e);

        }finally{

            setSaving(false);

        }


    }

    useEffect(() => {

        if( formValues.ghe ){

            getFuncByGhe( formValues.ghe );

        }else{

            console.log('valor em branco')

        }

    }, [ formValues.ghe ])

    const getFuncByGhe = async ( GHE ) => {

        try{

            const { data } = await axios.post('http://localhost/Painel/API/Treinamentos/GetFuncByGhe/', {
                ghe: GHE
            })

            console.log( data ); 

        }catch( e ){

            console.log( e )

        }

    }

    return (
        <>
            {loading ? <SmallLoading /> :
                <form onSubmit={handleSubmit} >
                    <FormComp
                        title="TREINAMENTO"
                        type="select"
                        options={treinamentos}
                        changeFunc={(e) => handleChange('treinamento', e)}
                        valor={formValues.treinamento}
                        required
                        disabled={saving}
                    />
                    <FormComp
                        title="CENTRO CUSTO"
                        type="select"
                        options={obras}
                        changeFunc={(e) => handleChange('centroCusto', e)}
                        valor={formValues.centroCusto}
                        required
                        disabled={saving}
                    />
                    <FormComp
                        title="CONTRATO"
                        type="select"
                        options={contratos}
                        changeFunc={(e) => handleChange('contrato', e)}
                        valor={formValues.contrato}
                        required
                        disabled={saving}
                    />
                    <FormComp
                        title="PREVISAO"
                        type="month"
                        changeFunc={(e) => handleChange('previsao', e)}
                        valor={formValues.previsao}
                        required
                        disabled={saving}
                    />
                    <FormComp
                        title="GHE"
                        type="select"
                        options={
                            ghes.map( obj => (
                                <option key={ obj.IDENTIFIER } value={ obj.IDENTIFIER } >{ obj.GHE }</option>
                            ))
                        }
                        mapped
                        valor={ formValues.ghe }
                        changeFunc={( e ) => handleChange('ghe', e)}
                        required
                        disabled={ saving }
                    />
                    <FormComp
                        title="FUNÇÃO"
                        type="select"
                        options={[]}
                    />
                    <FormComp
                        type="submit"
                        valor={ saving ? "SALVANDO" : "SALVAR" }
                    />
                </form>
            }
        </>
    )

}

export default FormProgram;