import FormComp from '../FormComp';
import styles from './SquareTransfer.module.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { formatCPF } from '../../Funcoes/FormatCPF';
import { toast } from 'react-toastify';
import { IoCloseSharp } from "react-icons/io5";

const SquareTransfer = ({ close, getNewValues, obras, objEdit, editing }) => {

    useEffect(()=>{

        if( objEdit && objEdit.motivo === 'TRANSFERENCIA' ){

            setFormValues({
                matricula: '',
                nome: objEdit.nome,
                cpf: objEdit.cpf,
                funcao: objEdit.funcao,
                ccOrigem: objEdit.cc_origem ? objEdit.cc_origem : '',
                ccDestino: objEdit.cc_destino ? objEdit.cc_destino : '',
                prevCheg: objEdit.prev_chegada ? objEdit.prev_chegada : '',
                docSesmt : objEdit.data_doc_sesmt ? objEdit.data_doc_sesmt : '',
                cadPortal: objEdit.data_cad_portal ? objEdit.data_cad_portal : '',
                integracao: objEdit.data_integracao ? objEdit.data_integracao : '',
                entradaSite: objEdit.data_entrada_site? objEdit.data_entrada_site : '',
                id: objEdit.id
            })

        }

    },[ objEdit ])

    const [formValues, setFormValues] = useState({
        matricula: '',
        nome: '',
        cpf: '',
        funcao: '',
        ccOrigem: '',
        ccDestino: '',
        prevCheg: '',
        docSesmt: '',
        cadPortal: '',
        integracao: '',
        entradaSite: ''
    })

    const clearValues = async () => {

        setFormValues({
            matricula: '',
            nome: '',
            cpf: '',
            funcao: '',
            ccOrigem: '',
            ccDestino: '',
            prevCheg: '',
            docSesmt: '',
            cadPortal: '',
            integracao: '',
            entradaSite: ''
        })

    }

    const [savingItem, setSavingItem] = useState( false )

    const handleSubmit = async (e) => {

        e.preventDefault();
        setSavingItem(true);

        let endpointApi = editing ? 'https://sistemaintegrado.palmont.com.br/Painel/API/EditTransfer/' : 'https://sistemaintegrado.palmont.com.br/Painel/API/SaveTransfer/'

        try {

            const { data } = await toast.promise(
                axios.post(
                    endpointApi,
                    formValues,
                    {
                        withCredentials: true
                    }
                ),
                {
                    pending: 'SALVANDO INFORMAÇÕES',
                    success: 'DADOS SALVOS COM SUCESSO',
                    error: 'FALHA AO SALVAR DADOS'
                }
            )

            console.log( data );
            
            await getNewValues();
            clearValues();
            close();

        } catch (error) {

            console.log(error)

        }finally{

            setSavingItem( false );

        }

    }

    const handleChange = (value, name) => {

        setFormValues(prev => ({
            ...prev,
            [name]: value
        }))

    }

    useEffect(() => {

        const getColab = async () => {

            try {

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetColabInfo/', {
                    matricula: formValues.matricula
                })

                if (data.length === 1) {

                    const colab = data[0]

                    setFormValues(prev => ({

                        ...prev,
                        nome: colab.NOME,
                        cpf: colab.CPF,
                        funcao: colab.FUNCAO

                    }))

                } else {

                    alert('INSIRA UMA MATRICULA VÁLIDA')

                }

            } catch (error) {

                console.log(error.message)

            }

        }

        if (formValues.matricula.length === 6) {

            getColab();

        } else {

            setFormValues(prev => ({

                ...prev,
                nome: '',
                cpf: '',
                funcao: ''

            }))

        }

    }, [formValues.matricula])

    return (
        <div className={styles.container} >
            <div className={styles.header} >
                <div className={styles.closeBtn} onClick={() =>{

                    clearValues();
                    close();

                }}>
                    <IoCloseSharp />
                </div>
            </div>
            <div className={styles.formContainer} >

                <form>
                    <FormComp
                        title="MATRICULA"
                        type="text"
                        valor={formValues.matricula}
                        changeFunc={(e) => handleChange(e.target.value, 'matricula')}
                        maxLength={6}
                        disabled={ savingItem }
                    />
                    <FormComp
                        title="NOME"
                        type="text"
                        disabled
                        valor={formValues.nome}
                    />
                    <FormComp
                        title="CPF"
                        type="text"
                        disabled
                        valor={formatCPF(formValues.cpf)}
                    />
                    <FormComp
                        title="FUNÇÃO"
                        type="text"
                        disabled
                        valor={formValues.funcao}
                    />
                    <FormComp
                        title="CC ORIGEM"
                        type="select"
                        options={obras}
                        valor={formValues.ccOrigem}
                        changeFunc={(e) => handleChange(e.target.value, 'ccOrigem')}
                        disabled={ savingItem }
                    />
                    <FormComp
                        title="CC DESTINO"
                        type="select"
                        options={obras}
                        valor={formValues.ccDestino}
                        changeFunc={(e) => handleChange(e.target.value, 'ccDestino')}
                        disabled={ savingItem }
                    />
                    <FormComp
                        title="PREV. CHEGADA"
                        type="date"
                        valor={formValues.prevCheg}
                        changeFunc={(e) => handleChange(e.target.value, 'prevCheg')}
                        disabled={ savingItem }
                    />
                    <FormComp
                        title="DOC. SESMT"
                        type="date"
                        valor={formValues.docSesmt}
                        changeFunc={(e) => handleChange(e.target.value, 'docSesmt')}
                        disabled={ savingItem }
                    />
                    <FormComp
                        title="CAD. PORTAL"
                        type="date"
                        valor={formValues.cadPortal}
                        changeFunc={(e) => handleChange(e.target.value, 'cadPortal')}
                        disabled={ savingItem }
                    />
                    <FormComp
                        title="INTEGRAÇÃO"
                        type="date"
                        valor={formValues.integracao}
                        changeFunc={(e) => handleChange(e.target.value, 'integracao')}
                        disabled={ savingItem }
                    />
                    <FormComp
                        title="ENTRADA NO SITE"
                        type="date"
                        valor={formValues.entradaSite}
                        changeFunc={(e) => handleChange(e.target.value, 'entradaSite')}
                        disabled={ savingItem }
                    />

                    <button onClick={handleSubmit} disabled={ savingItem } type="submit">SALVAR</button>

                </form>

            </div>
        </div>
    )

}

export default SquareTransfer;