import style from './GenericBody.module.css';

const GenericBody = ({ children }) => {

    return(
        <div className={ style.container } >
            { children }
        </div>
    )

}

export default GenericBody;