import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../Componentes/NavBar";
import RacBody from "../Componentes/RAC/RacBody";
import Overlay from "../Componentes/Overlay";
import { useCallback, useEffect, useState } from "react";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";
import RacForm from "../Componentes/RAC/RacForm";
import axios from "axios";
import { ToastContainer } from 'react-toastify';

const RAC = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const [racs, setRacs] = useState([]);
    const [racsFilter, setRacsFilter] = useState([]);

    const [sidebar, setSidebar] = useState(false);
    const [overForm, setOverForm] = useState(false);

    const getValues = async () => {

        try {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/RAC/GetRacs/')

            console.log(data)

            setRacs(data);
            setRacsFilter(data);

        } catch (error) {

            console.error(error);

        }

    }

    const handleFilter = useCallback(( obraFilter, contratoFilter, fonteFilter, tipoFilter, processoFilter, statusFilter, situacaoFilter, preenchidosFilter ) => {

        const filtered = racs.filter( item => {
    
            const obra = obraFilter.length === 0 || obraFilter.some( obj => (item.centro_custo || '').includes( obj.value ) )
            const contrato = contratoFilter.length === 0 || contratoFilter.some( obj => (item.contrato || '').includes( obj.value ) )
            const fonte = fonteFilter.length === 0 || fonteFilter.some( obj => (item.fonte || '').includes( obj.value ) )
            const tipo = tipoFilter.length === 0 || tipoFilter.some( obj => (item.tipo_nc || '').includes( obj.value ) )
            const processo = processoFilter.length === 0 || processoFilter.some( obj => (item.setor || '').includes( obj.value ) )
            const status = statusFilter.length === 0 || statusFilter.some( obj => (item.status || '').includes( obj.value ) )
            const situacao = situacaoFilter.length === 0 || situacaoFilter.some( obj => (item.STATUS || '').includes( obj.value ) )
            const preenchidos = preenchidosFilter.length === 0 || preenchidosFilter.some( obj => (item.created_by || '').includes( obj.value ) )
    
            return obra && contrato && fonte && tipo && processo && status && situacao && preenchidos
    
        })
    
        setRacsFilter( filtered )
    
    }, [racs])
    

    useEffect(() => {

        getValues();

    }, [])

    const openSideBar = () => {

        setSidebar(true);

    }

    const closeSideBar = () => {

        setSidebar(false);

    }

    const openForm = () => {

        setSidebar(false);
        setOverForm(true);

    }

    const closeForm = () => {

        setOverForm(false);
        navigate("/RAC");

    }

    const updateId = ( id ) => {

        navigate(`/RAC/${ id }`);

    }

    return (
        <>
            <NavBar
                title="RAC's"
                setSideBar={openSideBar}
            />
            <RacBody
                racs={racsFilter}
                racsToFilter={racs}
                openEdit={updateId}
                handleFilter={handleFilter}
            />

            {(id || overForm) &&
                <Overlay
                    state={true}
                >
                    <RacForm
                        closeForm={closeForm}
                        updateItens={getValues}
                    />
                </Overlay>}

            {
                sidebar &&
                <Overlay
                    state={true}
                    changeContent={closeSideBar}
                >
                    <SideMenu>
                        <SideMenuBtn
                            button="NOVA RAC"
                            clickFunc={openForm}
                        />
                    </SideMenu>

                </Overlay>
            }

            <ToastContainer
                style={{
                    zIndex: 99999
                }}
                autoClose={3000}
                position="top-center"
                theme="dark"
            />

        </>
    )

}

export default RAC;