import styles from './Recisoes.module.css';

import Select, { components } from 'react-select';
import { useState, useEffect, useRef } from 'react';
import { MdClose, MdCheck } from "react-icons/md";
import Tabela from '../Tabela';

const RecisoesBody = ({ dados, onFilter, obras, funcoes, encarregados, onClick }) => {
    const [filterName, setFilterName] = useState('');
    const [filterMat, setFilterMat] = useState('');
    const [filterFunc, setFilterFunc] = useState([]);
    const [filterObras, setFilterObras] = useState([]);
    const [filterEnc, setFilterEnc] = useState([]);
    const [filterSituacao, setFilterSituacao] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterPeriodo, setFilterPeriodo] = useState(null);
    const [linhas, setLinhas] = useState(100);

    const tabelaRef = useRef( null );

    useEffect(() => {

        const element = tabelaRef.current;

        if (!element) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = element;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setLinhas((prev) => prev + 100);
            }

        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };

    }, []);

    useEffect(() => {

        onFilter(filterName, filterMat, filterFunc, filterObras, filterEnc, filterSituacao, filterStatus, filterPeriodo);

    }, [filterName, filterMat, filterFunc, filterObras, filterEnc, filterSituacao, filterStatus, filterPeriodo, onFilter]);

    const handleChange = (selected, name) => {

        const valor = selected.map(obj => obj.value)

        switch (name) {

            case 'funcao':
                setFilterFunc(valor)
                break;

            case 'obra':
                setFilterObras(valor)
                break;

            case 'encarregado':
                setFilterEnc(valor)
                break;

            case 'situacao':
                setFilterSituacao(valor)
                break;

            case 'status':
                setFilterStatus(valor)
                break;

            case 'periodo':
                setFilterPeriodo(valor)
                break;

            default:
                setFilterName(null)

        }

    }

    const handleChangeSit = (option) => {
        setFilterSituacao(option ? option.value : '');
    };

    const handleChangeSt = (option) => {
        setFilterStatus(option ? option.value : '');
    };

    const handleChangePeriodo = (option) => {
        setFilterPeriodo(option ? option.value : '');
    };

    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`;
        }
        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    const getPeriodo = (obj, dias) => {

        let exp = null;

        if (dias === 45) {

            exp = obj.EXP1

        } else if (dias === 90) {

            exp = obj.EXP2

        }


        if (exp === null) {

            if (obj.STATUS === "DESLIGADO") {

                return (<td className={styles.errorCol} > <MdClose size={16} /> </td>)

            } else if (obj.DIAS_ADMISSAO <= dias) {

                return (<td> <button onClick={() => onClick(obj, dias)} > GERAR </button> </td>)

            } else {

                return (<td className={styles.errorCol} > <MdClose size={16} /> </td>)

            }

        } else {

            return (<td className={styles.checkCol} ><MdCheck size={16} /> </td>)

        }

    }

    return (
        <div className={styles.RecisoesBody}>
            <div className={styles.ContainerInfo}>
                <div className={styles.ContainerFilters}>
                    <div className={styles.ContainerFilter}>
                        <input
                            type='text'
                            placeholder='NOME...'
                            value={filterName}
                            onChange={(e) => setFilterName(e.target.value)}
                        />
                    </div>
                    <div className={styles.ContainerFilter}>
                        <input
                            type='text'
                            placeholder='MATRICULA...'
                            value={filterMat}
                            maxLength={6}
                            onChange={(e) => {
                                let value = e.target.value.replace(/\D/g, '');
                                setFilterMat(value);
                            }}
                        />
                    </div>
                    <div className={styles.ContainerFilter}>
                        <Select
                            isMulti
                            onChange={(selected) => handleChange(selected, 'funcao')}
                            options={funcoes}
                            placeholder='FUNÇÃO...'
                            components={{ ValueContainer }}
                            className={styles.customSelect}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                        />
                    </div>
                    <div className={styles.ContainerFilter}>
                        <Select
                            isMulti
                            onChange={(selected) => handleChange(selected, 'obra')}
                            options={obras}
                            placeholder='CENTRO CUSTO...'
                            components={{ ValueContainer }}
                            className={styles.customSelect}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                        />
                    </div>
                    <div className={styles.ContainerFilter}>
                        <Select
                            isMulti
                            onChange={(selected) => handleChange(selected, 'encarregado')}
                            options={encarregados}
                            placeholder='ENCARREGADO...'
                            components={{ ValueContainer }}
                            className={styles.customSelect}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                        />
                    </div>
                    <div className={styles.ContainerFilter}>
                        <Select
                            options={[
                                { label: 'QUEBRA DE CONTRATO', value: 'QUEBRA DE CONTRATO' },
                                { label: 'AVISO PRÉVIO', value: 'AVISO PRÉVIO' }
                            ]}
                            onChange={handleChangeSit}
                            placeholder='SITUAÇÃO...'
                            components={{ ValueContainer }}
                            className={styles.customSelect}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                    <div className={styles.ContainerFilter}>
                        <Select
                            onChange={handleChangeSt}
                            options={[
                                { label: 'ATIVO', value: 'ATIVO' },
                                { label: 'DESLIGADO', value: 'DESLIGADO' }
                            ]}
                            placeholder='STATUS...'
                            components={{ ValueContainer }}
                            className={styles.customSelect}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                    <div className={styles.ContainerFilter}>
                        <Select
                            onChange={handleChangePeriodo}
                            options={[
                                { label: '60 A 90 DIAS', value: '60-90' },
                                { label: '90 A 120 DIAS', value: '90-120' }
                            ]}
                            placeholder='PERIODO TRABALHADO...'
                            components={{ ValueContainer }}
                            className={styles.customSelect}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            isSearchable={false}
                            isClearable={true}
                        />
                    </div>
                </div>
                <div className={styles.ContainerStats}>
                    <div className={styles.StatsSquare}>
                        <p>QUEBRA DE CONTRATO: {dados.filter(obj => obj.SITUACAO === 'QUEBRA DE CONTRATO').length}</p>
                        <p>AVISO PRÉVIO: {dados.filter(obj => obj.SITUACAO === 'AVISO PRÉVIO').length}</p>
                    </div>
                </div>
            </div>
            <Tabela ref={ tabelaRef } >
                <thead>
                    <tr>
                        <th>COLABORADOR</th>
                        <th>MATRICULA</th>
                        <th>FUNÇÃO</th>
                        <th>ENCARREGADO</th>
                        <th>CENTRO CUSTO</th>
                        <th>ADMISSÃO</th>
                        <th>DEMISSÃO</th>
                        <th>DIAS TRABALHADOS</th>
                        <th>SITUAÇÃO</th>
                        <th>1° PERIODO</th>
                        <th>2° PERIODO</th>
                        <th>STATUS</th>
                    </tr>
                </thead>
                <tbody>
                    {dados.slice(0, linhas).map((obj, index) => {

                        const classe = (obj.DIAS_ADMISSAO >= 35 && obj.DIAS_ADMISSAO <= 45 && obj.EXP1 === null) ||
                            (obj.DIAS_ADMISSAO >= 80 && obj.DIAS_ADMISSAO <= 90 && obj.EXP2 === null)

                        return (
                            <tr
                                className={classe ? styles.vencendo : ''}
                                key={index}
                            >
                                <td>{obj.NOME}</td>
                                <td>{obj.MATRICULA}</td>
                                <td>{obj.FUNCAO}</td>
                                <td>{obj.IMEDIATO ? obj.IMEDIATO : '-'}</td>
                                <td>{obj.CENTRO_CUSTO}</td>
                                <td>{obj.ADMISSAO}</td>
                                <td>{obj.DEMISSAO}</td>
                                <td>{obj.DIAS_ADMISSAO}</td>
                                <td>{obj.SITUACAO}</td>
                                <td>{getPeriodo(obj, 45)}</td>
                                <td>{getPeriodo(obj, 90)}</td>
                                <td>{obj.STATUS}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Tabela>
        </div>
    );
};

export default RecisoesBody;
