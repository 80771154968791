import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import LoadingScreen from "../Componentes/LoadingScreen";

const PrivateRoute = ({ requiredPermission = []}) => {
    const { user, loading, checkAuth } = useContext(AuthContext);
    const location = useLocation();
    const [isAuthChecked, setIsAuthChecked] = useState(false);

    useEffect(() => {

        if (!isAuthChecked) {

            checkAuth().then(() => setIsAuthChecked(true));

        }
        
    }, [isAuthChecked]);

    if (!isAuthChecked || loading) {

        return <LoadingScreen />;
        
    }

    if( !user ){

        return <Navigate to="/Login" replace />;

    }

    if( requiredPermission.length > 0 ){

        const userPermissions = user.PERMISSIONS || [];
        const hasPermission = requiredPermission.some(( perm ) => userPermissions.includes( perm ))

        if( !hasPermission ){

            console.log('User do not have permissions')
            console.log( userPermissions )
            return <Navigate to="/Home" replace />

        }

    }

    return <Outlet />;
};

export default PrivateRoute;
