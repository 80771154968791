import GenericBody from "../Componentes/GenericBody";
import GenericHeader from "../Componentes/GenericHeader";
import NavBar from "../Componentes/NavBar";
import Tabela from "../Componentes/Tabela";
import Filters from "../Componentes/Utils/Filters";
import Stats from "../Componentes/Utils/Stats";
import FilterContainer from "../Componentes/Utils/FilterContainer";
import FormContainer from "../Componentes/Utils/FormContainer";

import axios from "axios";
import { formatarData } from "../Funcoes/FormatarData";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useParams, useNavigate, useLocation, replace } from "react-router-dom";
import { saveAs } from "file-saver";
import Overlay from "../Componentes/Overlay";

import { HiOutlineDownload } from "react-icons/hi";
import { MdOutlineAdd } from "react-icons/md";
import { LuFileDown } from "react-icons/lu";
import { TbLoader2 } from "react-icons/tb";

import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";
import FormTreinamentos from "../Componentes/Treinamentos/FormTreinamentos";
import SaveAnexos from "../Componentes/Treinamentos/SaveAnexos";

const Treinamentos = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const location = useLocation();

    const [loadingItem, setLoadingItem] = useState(false);

    const [downloadingPDF, setDownloadingPDF] = useState({});

    const isViewing = location.pathname.includes("view");
    const isEditing = location.pathname.includes("edit");
    const isCreating = location.pathname.includes("new");

    const openedForm = location.pathname.includes("form");

    const [treinamentos, setTreinamentos] = useState([])
    const [treinamentosFilter, setTreinamentosFilter] = useState([])
    const [linhasTabela, setLinhasTabela] = useState(100);
    const [sidebar, setSidebar] = useState(false)

    const tabelaRef = useRef(null);

    const getTreinamentos = async () => {

        try {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GetTreinamentos/')
            //const { data } = await axios.get('http://localhost/Painel/API/Treinamentos/GetTreinamentos/')

            setTreinamentos(data)
            setTreinamentosFilter(data)

        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {

        getTreinamentos()

    }, [])

    useEffect(() => {

        const element = tabelaRef.current;

        if (!element) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = element;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setLinhasTabela((prev) => prev + 100);
            }

        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };

    }, []);

    const funcoes = useMemo(() => [...new Set(treinamentos.map((obj) => obj.funcao))].sort(), [treinamentos])
    const obras = useMemo(() => [...new Set(treinamentos.map((obj) => obj.local))].sort(), [treinamentos]);
    const contratos = useMemo(() => [...new Set(treinamentos.map((obj) => obj.contrato))].sort(), [treinamentos]);
    //const instrutores = useMemo(() => [...new Set(treinamentos.map((obj) => obj.instrutor))].sort(), [treinamentos]);
    //const instrutores_name = useMemo(() => [...new Set(treinamentos.map((obj) => obj.instrutor_name))].sort(), [treinamentos]);

    const instrutores = [
        ... new Map(
            treinamentos.map( item => [ item.instrutor_name, { label: item.instrutor_name, value: item.instrutor } ] )
        ).values()
    ].sort(( a, b ) => a.label.localeCompare( b.label ));


    const treinamentosName = useMemo(() => [...new Set(treinamentos.map((obj) => obj.treinamento))].sort(), [treinamentos]);
    const motivos = useMemo(() => [...new Set(treinamentos.map((obj) => obj.motivo))].sort(), [treinamentos]);
    const processos = useMemo(() => [...new Set(treinamentos.map((obj) => obj.processo))].sort(), [treinamentos]);
    const preenchidos = useMemo(() => [...new Set(treinamentos.map((obj) => obj.email))].sort(), [treinamentos]);

    const [colabName, setColabName] = useState([])
    const [funcSelected, setFuncSelected] = useState([])
    const [obrasSelected, setObrasSelected] = useState([])
    const [contratoSelected, setContratoSelected] = useState([])
    const [instrutoresSelected, setInstrutoresSelected] = useState([])
    const [treinamentosSelected, setTreinamentosSelected] = useState([])
    const [motivosSelected, setMotivosSelected] = useState([])
    const [processosSelected, setProcessosSelected] = useState([])
    const [anexadoSelect, setAnexadoSelect] = useState([])
    const [preenchidosSelected, setPreenchidosSelected] = useState([])
    const [dataIni, setDataIni] = useState('')
    const [dataFim, setDataFim] = useState('')

    const handleChange = (type, value) => {

        switch (type) {
            case 'name':
                setColabName(value)
                break;
            case 'funcao':
                setFuncSelected(value)
                break;
            case 'obra':
                setObrasSelected(value)
                break;
            case 'contrato':
                setContratoSelected(value)
                break;
            case 'instrutor':
                setInstrutoresSelected(value)
                break;
            case 'treinamento':
                setTreinamentosSelected(value)
                break;
            case 'motivo':
                setMotivosSelected(value)
                break;
            case 'processo':
                setProcessosSelected(value)
                break;
            case 'anexado':
                setAnexadoSelect(value)
                break;
            case 'preenchido':
                setPreenchidosSelected(value)
                break;
            case 'data_ini':
                setDataIni(value)
                break;
            case 'data_fim':
                setDataFim(value)
                break;
            default:
                console.log('Opção inválida')
        }

    }

    const handleFilter = useCallback(() => {
        setTreinamentosFilter(
            treinamentos.filter((item) => {
                const nome = !colabName || item.nome.includes(colabName);
                const funcao = !funcSelected.length || funcSelected.some(obj => (item.funcao || '').includes(obj.value));
                const obra = !obrasSelected.length || obrasSelected.some(obj => (item.obra || '').includes(obj.value));
                const contrato = !contratoSelected.length || contratoSelected.some(obj => (item.contrato || '').includes(obj.value));
                const instrut = !instrutoresSelected.length || instrutoresSelected.some(obj => (item.instrutor || '').includes(obj.value));
                const training = !treinamentosSelected.length || treinamentosSelected.some(obj => (item.treinamento || '').includes(obj.value));
                const motivo = !motivosSelected.length || motivosSelected.some(obj => (item.motivo || '').includes(obj.value));
                const processo = !processosSelected.length || processosSelected.some(obj => (item.processo || '').includes(obj.value));
                const preenchido = !preenchidosSelected.length || preenchidosSelected.some(obj => (item.email || '').includes(obj.value));

                const anexos =
                    !anexadoSelect.length ||
                    (anexadoSelect.some(obj => obj.value === 'ANEXADO') && item.certificado !== null) ||
                    (anexadoSelect.some(obj => obj.value === 'ANEXO PENDENTE') && item.certificado === null);

                const dataInicio = !dataIni || (item.data_inicio && new Date(item.data_inicio) >= new Date(dataIni));
                const dataTermino = !dataFim || (item.data_termino && new Date(item.data_termino) <= new Date(dataFim));


                return nome && funcao && obra && contrato && instrut && training && motivo && processo && anexos && preenchido && dataInicio && dataTermino;
            })
        );

    }, [treinamentos, colabName, funcSelected, obrasSelected, contratoSelected, instrutoresSelected, treinamentosSelected, motivosSelected, processosSelected, anexadoSelect, preenchidosSelected, dataIni, dataFim]);

    useEffect(() => {
        handleFilter();
    }, [handleFilter]);

    useEffect(() => {

        const getTreinamentoDetail = async () => {

            if (isCreating) return;

            setLoadingItem(true);

            try {

                const { data } = await axios.get(`https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GetTreinamento/index.php?id=${id}`);
                console.log(data);

            } catch (error) {

                console.log(error);

            } finally {

                setLoadingItem(false);

            }

        }

        getTreinamentoDetail();

    }, [id, isCreating])

    const closeOverlay = () => {

        navigate('/Treinamentos')

    }

    const openSidebar = () => {

        setSidebar(true);

    }

    const closeSidebar = () => {

        setSidebar(false);

    }

    const openForm = () => {

        setSidebar(false);
        navigate('/Treinamentos/form');

    }

    const closeForm = () => {

        navigate('/Treinamentos');

    }

    const handleDownload = (e, url, filename) => {

        e.stopPropagation();
        saveAs(url, filename);

    }

    const [fileOverlay, setFileOverlay] = useState(false)
    const [fileId, setFileId] = useState(null)

    const openAttachment = (e, id) => {

        e.stopPropagation();

        setFileOverlay(true);
        setFileId(id);

    }

    const closeAttachment = () => {

        setFileOverlay(false);
        setFileId(null);

    }

    const downloadCertificado = async (id, name, treinamento, e) => {

        e.stopPropagation();

        setDownloadingPDF((prev) => ({ ...prev, [id]: true }));

        try {

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GenerateCertificado/', {
                id_treinamento: id
            },
                { responseType: "blob" })

            const pdfblob = new Blob([data], { type: "application/pdf" });

            saveAs(pdfblob, `${treinamento} - ${name}`)

        } catch (error) {

            console.log(error);

        } finally {

            setDownloadingPDF((prev) => ({ ...prev, [id]: false }));

        }

    }

    const [ loadingList, setLoadingList ] = useState( false );

    const generateLista = async () => {

        const valores = treinamentosFilter
        setLoadingList( true )

        try{

            const formData = new FormData();
            formData.append('dados', JSON.stringify(valores))

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GenerateLista/', formData, { responseType: "blob" })
            const pdfblob = new Blob([data], { type: "application/pdf" })
            saveAs( pdfblob, 'lista-de-presenca.pdf' )

            console.log( data )

        }catch( error ){

            console.log( error )

        }finally{

            setLoadingList( false )

        }

    }

    const [ loadingCrachas, setLoadingCrachas ] = useState( false )

    const generateCrachas = async () => {

        const cpfList = [...new Set(treinamentosFilter.map( obj => obj.cpf ))]
        setLoadingCrachas( true )
        
        await Promise.all(
            cpfList.map( async ( cpf ) => {

                try{

                    const formData = new FormData();
                    formData.append('cpf', cpf )

                    const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/GenerateCracha/', formData, { responseType: 'blob' })
                    const pdfBlob = new Blob([ data ], { type: 'application/pdf' })
                    saveAs(pdfBlob, `CRACHA - ${cpf}.pdf` )

                }catch( error ){

                    console.log( error )

                }

            })
        )

        console.log('Finalizou');
        setLoadingCrachas( false )

    }


    return (
        <>
            <NavBar
                title="TREINAMENTOS"
                setSideBar={openSidebar}
            />
            <GenericBody>
                <GenericHeader>
                    <Filters columns={4} >
                        <FilterContainer
                            type="text"
                            placeholder="COLABORADOR..."
                            changeFunc={(value) => handleChange('name', value)}
                            valor={colabName}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="FUNÇÃO..."
                            options={funcoes}
                            optType="obj"
                            changeFunc={(value) => handleChange('funcao', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="CENTRO CUSTO..."
                            options={obras}
                            optType="obj"
                            changeFunc={(value) => handleChange('obra', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="CONTRATO..."
                            options={contratos}
                            optType="obj"
                            changeFunc={(value) => handleChange('contrato', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="INSTRUTOR..."
                            options={ instrutores }
                            changeFunc={(value) => handleChange('instrutor', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="TREINAMENTO..."
                            options={treinamentosName}
                            optType="obj"
                            changeFunc={(value) => handleChange('treinamento', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="MOTIVO..."
                            options={motivos}
                            optType="obj"
                            changeFunc={(value) => handleChange('motivo', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="PROCESSO..."
                            options={processos}
                            optType="obj"
                            changeFunc={(value) => handleChange('processo', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="ANEXOS..."
                            options={[
                                'ANEXADO', 'ANEXO PENDENTE'
                            ]}
                            optType="obj"
                            changeFunc={(value) => handleChange('anexado', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="PREENCHIDO POR..."
                            options={preenchidos}
                            optType="obj"
                            changeFunc={(value) => handleChange('preenchido', value)}
                        />
                        <FilterContainer
                            type="date"
                            valor={dataIni}
                            changeFunc={(value) => handleChange('data_ini', value)}
                        />
                        <FilterContainer
                            type="date"
                            valor={dataFim}
                            changeFunc={(value) => handleChange('data_fim', value)}
                        />
                    </Filters>
                    <Stats>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 'auto',
                            fontWeight: 'bold'
                        }} >
                            <p style={{ margin: 'auto auto 10px auto' }} >TOTAL DE TREINAMENTOS: {treinamentosFilter.length}</p>
                            <p style={{ margin: '0' }} >VÁLIDOS: {treinamentosFilter.filter(obj => obj.status === 'VALIDO').length}</p>
                            <p style={{ margin: '0' }} >A VENCER: {treinamentosFilter.filter(obj => obj.status === 'A VENCER').length}</p>
                            <p style={{ margin: '0' }} >VENCIDOS: {treinamentosFilter.filter(obj => obj.status === 'VENCIDO').length}</p>
                        </div>
                    </Stats>
                </GenericHeader>
                <Tabela ref={tabelaRef} >
                    <thead>
                        <tr>
                            <th>COLABORADOR</th>
                            <th>FUNÇÃO</th>
                            <th>CONTRATO</th>
                            <th>TREINAMENTO</th>
                            <th>VÁLIDADE</th>
                            <th>STATUS</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            treinamentosFilter.slice(0, linhasTabela).map((obj) => (
                                <tr
                                    key={obj.id}
                                    onClick={() => navigate(`/Treinamentos/form/${obj.id}`)}
                                    style={{
                                        color: obj.status === 'VENCIDO' ? '#b30000' : obj.status === 'A VENCER' ? '#ff4500' : 'black',
                                        fontWeight: (obj.status === 'VENCIDO' || obj.status === 'A VENCER') ? 'bold' : 'normal'
                                    }}
                                >
                                    <td>{obj.nome}</td>
                                    <td>{obj.funcao}</td>
                                    <td>{obj.contrato ? obj.contrato : '-'}</td>
                                    <td>{obj.treinamento}</td>
                                    <td>{formatarData(obj.validade)}</td>
                                    <td>{obj.status}</td>
                                    <td>
                                        <div title="BAIXAR MODELO" >
                                            {downloadingPDF[obj.id] ?
                                                <TbLoader2
                                                    size={20}
                                                    style={{
                                                        color: "black",
                                                        animation: "spin 1s linear infinite",
                                                        "@keyframes spin": "0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); }"
                                                    }}
                                                />
                                                :
                                                <LuFileDown size={20} style={{ color: "black" }} onClick={(e) => downloadCertificado(obj.id, obj.nome, obj.treinamento, e)} />
                                            }
                                        </div>
                                    </td>
                                    <td>{obj.certificado ? (
                                        <HiOutlineDownload title="BAIXAR CERTIFICADO" size={20} style={{ color: "green", cursor: "pointer" }} onClick={(e) => handleDownload(e, obj.certificado, obj.treinamento + ' | ' + obj.nome)} />
                                    ) : (
                                        <div><MdOutlineAdd title="ANEXAR CERTIFICADO" size={20} style={{ color: "red", cursor: "pointer" }} onClick={(e) => openAttachment(e, obj.id)} /></div>
                                    )}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Tabela>
            </GenericBody>
            {
                sidebar &&
                <Overlay
                    state={true}
                    changeContent={closeSidebar}
                >
                    <SideMenu>
                        <SideMenuBtn
                            button="NOVO TREINAMENTO"
                            clickFunc={openForm}
                        />
                        <SideMenuBtn
                            button="GERAR LISTA DE PRESENÇA"
                            clickFunc={ generateLista }
                            isLoading={ loadingList }
                        />
                        <SideMenuBtn
                            button="GERAR CRACHÁ"
                            clickFunc={ generateCrachas }
                            isLoading={ loadingCrachas }
                        />
                    </SideMenu>
                </Overlay>
            }

            {
                openedForm &&
                <Overlay
                    state={true}
                >
                    <FormContainer
                        closeFunc={closeForm}
                    >
                        <FormTreinamentos
                            closeFunc={closeForm}
                            updateTreinamentos={getTreinamentos}
                        />
                    </FormContainer>
                </Overlay>
            }

            {

                fileOverlay &&
                <Overlay state={true} >
                    <SaveAnexos
                        close={closeAttachment}
                        idSelected={ fileId }
                        updateTable={ getTreinamentos }
                    />
                </Overlay>

            }

        </>
    )

}

export default Treinamentos;