import { formatarData } from '../../../Funcoes/FormatarData';
import Tabela from '../../Tabela';
import style from './PlanejamentoBody.module.css';

import { useRef, useEffect, useState } from 'react';

const PlanejamentoBody = ({ planejamento, editItem }) => {

    const tabelaRef = useRef(null);

    const [linhasTabela, setLinhasTabela] = useState(100);

    useEffect(() => {

        const element = tabelaRef.current;

        if (!element) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = element;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setLinhasTabela((prev) => prev + 100);
            }

        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <div className={style.container} >
            <Tabela>
                <thead>
                    <tr>
                        <th>PLANEJADO POR</th>
                        <th>AUDITOR</th>
                        <th>AUDITADO</th>
                        <th>SETOR</th>
                        <th>DATA PLANEJADA</th>
                        <th>DATA REALIZADA</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        planejamento && planejamento.slice(0, linhasTabela).map(obj => (
                            <tr
                                key={obj.id}
                                onClick={() => editItem(obj.id)}
                            >
                                <td>{obj.user}</td>
                                <td>{obj.auditor}</td>
                                <td>{obj.auditado}</td>
                                <td>{obj.setor}</td>
                                <td>{formatarData(obj.data_planej, true)}</td>
                                <td>{obj.ultima_data ? formatarData(obj.ultima_data, true) : '-'}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Tabela>
        </div>
    )

}

export default PlanejamentoBody;