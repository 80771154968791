import style from './InfoBody.module.css';

const InfoBody = ({ children }) => {

    return(
        <div className={ style.content } >
            { children }
        </div>
    )

}

export default InfoBody;