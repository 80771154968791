import styles from './FolgaBody.module.css';

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import Tabela from '../Tabela';

const FolgaBody = ({ dados, obras, municipios, estados, onFilter, selectObj, loadingValues }) => {

    console.log(loadingValues);

    const totalValor = (dados.reduce((acc, item) => acc + ((item.VALOR ? item.VALOR : 0) * item.QUANTIDADE), 0)).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const totalPessoas = dados.reduce((acc, item) => acc + parseInt(item.QUANTIDADE), 0);

    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`;
        }
        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    const [obraFilter, setObraFilter] = useState([])
    const [municipioFilter, setMunicipioFilter] = useState([])
    const [estadoFilter, setEstadoFilter] = useState([])
    const [distanciaFilter, setDistanciaFilter] = useState(null)

    const handleChange = (selected, name) => {

        switch (name) {

            case 'obra':
                setObraFilter(selected);
                break;
            case 'municipio':
                setMunicipioFilter(selected);
                break;
            case 'estado':
                setEstadoFilter(selected);
                break;
            case 'distancia':
                setDistanciaFilter(selected.value);
                break;

        }

    }

    useEffect(() => {
        onFilter(obraFilter, municipioFilter, estadoFilter, distanciaFilter);
    }, [obraFilter, municipioFilter, estadoFilter, distanciaFilter]);

    const handleChangeDist = (option) => {

        setDistanciaFilter(option ? option.value : '')

    }

    return (
        <div className={styles.bodyContainer} >
            <div className={styles.header} >
                <div className={styles.filtersContainer} >

                    <div className={styles.filterChild} >
                        <Select
                            className={styles.customSelect}
                            placeholder={"CENTRO CUSTO..."}
                            components={{ ValueContainer }}
                            options={obras.map(obra => ({ value: obra, label: obra }))}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            value={obraFilter}
                            onChange={(selected) => handleChange(selected, 'obra')}
                        />
                    </div>
                    <div className={styles.filterChild} >
                        <Select
                            className={styles.customSelect}
                            placeholder={"MUNICIPIO..."}
                            components={{ ValueContainer }}
                            options={municipios.map(municip => ({ value: municip, label: municip }))}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            value={municipioFilter}
                            onChange={(selected) => handleChange(selected, 'municipio')}
                        />
                    </div>
                    <div className={styles.filterChild} >
                        <Select
                            className={styles.customSelect}
                            placeholder={"ESTADO..."}
                            components={{ ValueContainer }}
                            options={estados.map(estado => ({ value: estado, label: estado }))}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            value={estadoFilter}
                            onChange={(selected) => handleChange(selected, 'estado')}
                        />
                    </div>
                    <div className={styles.filterChild} >
                        <Select
                            className={styles.customSelect}
                            placeholder={"DISTANCIA..."}
                            components={{ ValueContainer }}
                            options={[
                                { value: "0 - 300", label: "0KM - 300KM" },
                                { value: "301 - 500", label: "301KM - 500KM" },
                                { value: "501 - 1000", label: "501KM - 1000KM" },
                                { value: "1001 - 2000", label: "1001KM - 2000KM" },
                                { value: "2001 >", label: "ACIMA DE 2001KM" }
                            ]}
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            isClearable
                            onChange={handleChangeDist}
                        />
                    </div>
                </div>
                <div className={styles.statsContainer} >
                    <div className={styles.statsText} >
                        <p>VALOR GERAL: {totalValor}</p>
                        <p>VALOR MÉDIO:</p>
                        <p>TOTAL DE PESSOAS: {totalPessoas}</p>
                    </div>
                </div>
            </div>
            <Tabela loading={ loadingValues } >
                <thead>
                    <tr>
                        <th>CENTRO CUSTO</th>
                        <th>MUNICIPIO</th>
                        <th>ESTADO</th>
                        <th>DISTANCIA</th>
                        <th>DIAS PRAZO</th>
                        <th>DIAS GOZO</th>
                        <th>PESSOAS</th>
                        <th>VALOR</th>
                    </tr>
                </thead>
                <tbody>
                    {dados.map(obj => (
                        <tr key={obj.id} onClick={() => { selectObj(obj) }} >
                            <td>{obj.CENTRO_CUSTO} </td>
                            <td>{obj.MUNICIPIO} </td>
                            <td>{obj.ESTADO} </td>
                            <td>{obj.DISTANCIA ? obj.DISTANCIA.toLocaleString('pt-BR') + ' KM' : "-"}</td>
                            <td>{obj.DIAS_PRAZO ? obj.DIAS_PRAZO : '-'} </td>
                            <td>{obj.DIAS_GOZO ? obj.DIAS_GOZO : '-'} </td>
                            <td>{obj.QUANTIDADE}</td>
                            <td>{"R$ " + parseFloat(obj.VALOR).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                    ))}
                </tbody>
            </Tabela>
        </div>
    )

}

export default FolgaBody;