import style from './SideMenuBtn.module.css';

import { AiFillMedicineBox, AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoHome, IoPersonAddSharp, IoDocumentText } from "react-icons/io5";
import { FaMapMarkerAlt, FaDollarSign } from "react-icons/fa";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { RiFileExcel2Line, RiHealthBookFill } from "react-icons/ri";
import { BiSolidHelpCircle, BiTransfer } from "react-icons/bi";
import { GiHealthNormal } from "react-icons/gi";
import { IoMdAdd, IoMdClose, IoMdCheckmark } from "react-icons/io";
import { BsListCheck } from "react-icons/bs";
import { HiDocumentText } from "react-icons/hi";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { CiViewList } from "react-icons/ci";
import { BsFilePerson } from "react-icons/bs"
import { LuFileBadge, LuFilePenLine } from "react-icons/lu";
import { PiLeafFill } from "react-icons/pi";
import { GrConfigure } from "react-icons/gr";
import { FiClock } from "react-icons/fi";


const iconMap = {
    'AFASTAMENTOS' : RiHealthBookFill,
    'ATENDIMENTOS' : BiSolidHelpCircle,
    'EXAMES OCUPACIONAIS' : GiHealthNormal,
    'FOLGA DE CAMPO' : FaMapMarkerAlt,
    'FOLHA DE PAGAMENTO' : FaMoneyCheckDollar,
    'GERAR RELATÓRIO' : RiFileExcel2Line,
    'PROCESSO ADM/TRANSF' : IoPersonAddSharp,
    'CONTROLE DE EXPERIÊNCIA' : IoDocumentText,
    'REPUBLICAS' : IoHome,
    'NOVA REPUBLICA' : IoHome,

    'SOLICITAR ASO': AiFillMedicineBox,
    'BENEFÍCIOS': FaDollarSign,
    'NOVO COLABORADOR': IoPersonAddSharp,
    'TRANSFERIR COLABORADOR': BiTransfer,
    'NOVA AUDITORIA': IoMdAdd,
    'NOVA RAC': IoMdAdd,
    'REGISTRAR NOVO ITEM': IoMdAdd,
    'PLANEJAR SETOR': IoMdAdd,
    "ITENS PENDENTES": BsListCheck,
    "CANCELAR AUDITORIA": IoMdClose,
    "FINALIZAR AUDITORIA": IoMdCheckmark,
    "RAC": HiDocumentText,
    "AUDITORIA INTERNA": HiOutlineClipboardDocumentList,
    "NOVO TREINAMENTO": IoMdAdd,
    "GERAR LISTA DE PRESENÇA": CiViewList,
    "GERAR CRACHÁ": BsFilePerson,
    "TREINAMENTOS | FUNÇÃO": BsListCheck,
    "TREINAMENTOS" : LuFileBadge,
    "DESVIOS" : LuFilePenLine,
    "INSPEÇÃO DE CAMPO" : IoDocumentText,
    "INSPEÇÃO AMBIENTAL" : PiLeafFill,
    "PAINEL DE CONTROLE" : GrConfigure,

    "PROGRAMAR TREINAMENTO" : FiClock,
    "CADASTRAR TREINAMENTO" : IoMdAdd
}

const SideMenuBtn = ({ button, clickFunc, isLoading }) => {

    const IconComponent = iconMap[ button ]

    return(
        <div
            className={ style.sideMenuBtn }
            onClick={ !isLoading ? clickFunc : undefined }
            role='button'
        >

            <div className={ style.divIcon } >
                { isLoading ? <AiOutlineLoading3Quarters className={ style.spinner } /> : <IconComponent /> }
            </div>
            <p>{ button }</p>
        </div>
    )

}

export default SideMenuBtn;