import style from './AuditBody.module.css';

import Select, { components } from 'react-select';
import { formatarData } from '../../../Funcoes/FormatarData';
import { MdModeEdit } from "react-icons/md";
import { IoEye } from "react-icons/io5";
import { useEffect, useRef, useState } from 'react';
import Tabela from '../../Tabela';

const AuditBody = ({ dados, editItem, openView, dadosToFilter, handleFilter }) => {

    const [obrasSelected, setObrasSelected] = useState([])
    const [contratosSelected, setContratosSelected] = useState([])
    const [statusSelected, setStatusSelected] = useState([])
    const [auditoresSelected, setAuditoresSelected] = useState([])

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const ValueContainer = ({ children, ...props }) => {

        let [values, input] = children
        if (Array.isArray(values)) {

            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`

        }

        return (

            <components.ValueContainer {...props} >
                {values}
                {input}
            </components.ValueContainer>

        )

    }

    const obras = [...new Set(dadosToFilter.map(obj => obj.centro_custo))].sort();
    const contratos = [...new Set(dadosToFilter.map(obj => obj.contrato))].sort();
    const auditores = [...new Set(dadosToFilter.flatMap(obj => obj.auditores))].sort();

    useEffect(() => {

        handleFilter(obrasSelected, contratosSelected, statusSelected, auditoresSelected, startDate, endDate)

    }, [obrasSelected, contratosSelected, statusSelected, auditoresSelected, startDate, endDate])

    const previstos = dados.filter(item => item.status === 'PREVISTO').length
    const abertos = dados.filter(item => item.status === 'ABERTO').length
    const finalizados = dados.filter(item => item.status === 'FINALIZADO').length
    const cancelados = dados.filter(item => item.status === 'CANCELADO').length
    const reprogramados = dados.filter(item => item.status === 'REPROGRAMADO').length

    const [ linhasTabela, setLinhasTabela ] = useState( 100 );

    const tabelaRef = useRef( null );

    useEffect(() => {

        const element = tabelaRef.current;

        if (!element) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = element;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setLinhasTabela((prev) => prev + 100);
            }

        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <div className={style.container} >
            <div className={style.header} >
                <div className={style.containerFilters} >
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="CENTRO CUSTO..."
                            options={obras.map(obj => ({
                                value: obj, label: obj
                            }))}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setObrasSelected(selected) }}
                            value={obrasSelected}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="CONTRATO..."
                            options={contratos.map(obj => ({
                                value: obj, label: obj
                            }))}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setContratosSelected(selected) }}
                            value={contratosSelected}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="STATUS..."
                            options={[
                                { value: 'ABERTO', label: 'ABERTO' },
                                { value: 'CANCELADO', label: 'CANCELADO' },
                                { value: 'EM ATRASO', label: 'EM ATRASO' },
                                { value: 'FINALIZADO', label: 'FINALIZADO' },
                                { value: 'PREVISTO', label: 'PREVISTO' },
                                { value: 'REPROGRAMADO', label: 'REPROGRAMADO' }
                            ]}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setStatusSelected(selected) }}
                            value={statusSelected}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="AUDITOR..."
                            options={auditores.map(obj => ({
                                value: obj, label: obj
                            }))}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setAuditoresSelected(selected) }}
                            value={auditoresSelected}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <input
                            type='date'
                            value={startDate}
                            onChange={(e) => { setStartDate(e.target.value) }}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <input
                            type='date'
                            value={endDate}
                            onChange={(e) => { setEndDate(e.target.value) }}
                        />
                    </div>
                </div>
                <div className={style.containerStats} >
                    <div className={style.boxStats} >
                        <div className={style.stats1} >
                            <p>PREVISTO: {previstos}</p>
                            <p>ABERTO: {abertos}</p>
                            <p>FINALIZADO: {finalizados}</p>
                        </div>
                        <div className={style.stats2} >
                            <p>CANCELADOS: {cancelados}</p>
                            <p>EM ATRASO: 0</p>
                            <p>REPROGRAMADAS: {reprogramados}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Tabela ref={ tabelaRef } >
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>ID</th>
                        <th>CENTRO CUSTO</th>
                        <th>CONTRATO</th>
                        <th>AUDITORES</th>
                        <th>DATA PREV. ÍNICIO</th>
                        <th>DATA ÍNICIO</th>
                        <th>DATA PREV. FIM</th>
                        <th>DATA FIM</th>
                        <th>STATUS</th>
                        <th>PLANEJAMENTO</th>
                        <th>9001:2015</th>
                        <th>14001:2015</th>
                        <th>45001:2018</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dados.slice( 0, linhasTabela ).map(obj => (
                            <tr
                                key={obj.id}
                                onClick={() => window.open(`/AuditoriaInterna/Id/${obj.identifier}`)}
                            >
                                <td>
                                    <div
                                        onClick={(e) => {

                                            e.stopPropagation();
                                            editItem(obj.id);
                                            console.log(obj.id);

                                        }}
                                        className={style.editIconBox}
                                    >
                                        <MdModeEdit
                                            className={style.editIcon}
                                        />
                                    </div>
                                </td>
                                <td>
                                    {(obj.reprogramada === '*' || obj.cancelada === '*') &&
                                        <div
                                            className={style.editedLine}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                openView(obj.id)
                                            }}
                                        >
                                            <IoEye className={style.viewIcon} />
                                        </div>
                                    }
                                </td>
                                <td>{obj.id}</td>
                                <td>{obj.centro_custo}</td>
                                <td>{obj.contrato}</td>
                                <td>
                                    {obj.auditores.length > 0
                                        ? obj.auditores.join(' | ')
                                        : '-'}
                                </td>
                                <td>{obj.data_prev_inicio ? formatarData(obj.data_prev_inicio) : '-'}</td>
                                <td>{obj.data_inicio ? formatarData(obj.data_inicio) : '-'}</td>
                                <td>{obj.data_prev_fim ? formatarData(obj.data_prev_fim) : '-'}</td>
                                <td>{obj.data_final ? formatarData(obj.data_final) : '-'}</td>
                                <td>{obj.status}</td>
                                <td> <button onClick={(e) => {
                                    e.stopPropagation();
                                    window.open('/PlanejAuditoria/' + obj.identifier)
                                }} > PLANEJAMENTO </button> </td>
                                <td>{obj.iso_9001}/66</td>
                                <td>{obj.iso_14001}/38</td>
                                <td>{obj.iso_45001}/49</td>
                                <td>{obj.percent ? obj.percent : '100.00'}%</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Tabela>
        </div>
    )

}

export default AuditBody;