import InfoBody from "../Componentes/InfoPanel/InfoBody";
import MainContent from "../Componentes/InfoPanel/MainContent";
import SidePanel from "../Componentes/InfoPanel/SidePanel";

const InfoPanel = () => {

    return(
        <>
            <InfoBody>
                <SidePanel />
                <MainContent /> 
            </InfoBody>
        </>
    )

}

export default InfoPanel;