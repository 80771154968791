import style from './GheFuncoes.module.css';

import SmallLoading from '../../Utils/SmallLoading';
import FilterContainer from '../../Utils/FilterContainer';

import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { MdModeEdit } from "react-icons/md";
import Overlay from '../../Overlay';
import FormContainer from '../../Utils/FormContainer';
import FormComp from '../../FormComp';
import { AuthContext } from '../../../Auth/AuthProvider';


const GheFuncoes = () => {

    const { user } = useContext(AuthContext)

    const [responseData, setResponseData] = useState([])
    const [responseDataFilter, setResponseDataFilter] = useState([])

    const [funcoes, setFuncoes] = useState([])
    const [ghes, setGhes] = useState([])

    const [funcaoVal, setFuncaoVal] = useState([])
    const [gheVal, setGheVal] = useState([])

    const [editMode, setEditionMode] = useState(false)

    const [info, setInfo] = useState({
        funcao: '',
        ghe: ''
    })

    const [loading, setLoading] = useState(true);

    const getValues = async () => {

        setLoading(true);

        try {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/InfoPanel/Funcoes_GHE/', { withCredentials: true });

            setResponseData(data);
            setResponseDataFilter(data);

            setFuncoes([... new Set(data.map(item => item.FUNCAO).sort())])
            setGhes(["PENDENTES", ... new Set(data.map(item => item.GHE).sort())])


        } catch (error) {

            console.log(error);

        } finally {

            setLoading(false);

        }

    }

    useEffect(() => {

        getValues();

    }, [])

    const openEdit = (funcao, ghe) => {

        setEditionMode(true);
        setInfo({
            funcao: funcao,
            ghe: ghe === null ? '' : ghe
        });

    };

    const closeEdit = () => {

        setEditionMode(false);
        setInfo({
            funcao: '',
            ghe: ''
        })

    }

    const handleFilter = useCallback(() => {

        setResponseDataFilter(
            responseData.filter((item) => {

                const funcFilter = !funcaoVal.length || funcaoVal.some(obj => (item.FUNCAO || '').includes(obj.value))
                const gheFilter = !gheVal.length || gheVal.some(obj => {
                    if (obj.value === 'PENDENTES') return item.GHE === null
                    return (item.GHE || '').includes(obj.value);
                })

                return funcFilter && gheFilter

            })
        )

    }, [funcaoVal, gheVal])

    useEffect(() => {

        handleFilter()

    }, [handleFilter])

    const handleSubmit = async (e) => {

        e.preventDefault();

        try {

            const formData = new FormData();
            formData.append('FUNCAO', info.funcao);
            formData.append('GHE', info.ghe);

            await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/InfoPanel/SaveGhePanel/', formData);

            await getValues();
            closeEdit();

        } catch (e) {

            console.error(e);

        }

    }

    return (
        <>
            {loading
                ? <SmallLoading />
                :
                <>
                    <p className={style.title} >FUNÇÕES x GHE</p>
                    <div className={style.containerFilters} >
                        <FilterContainer
                            type="select"
                            placeholder="FUNÇÃO..."
                            options={funcoes}
                            optType="obj"
                            valor={funcaoVal}
                            changeFunc={(selected) => setFuncaoVal(selected)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="GHE..."
                            options={ghes}
                            optType="obj"
                            valor={gheVal}
                            changeFunc={(selected) => setGheVal(selected)}
                        />
                    </div>
                    <div className={style.container} >
                        <table>
                            <thead>
                                <tr>
                                    <th className={style.funcCol} >FUNÇÃO</th>
                                    <th className={style.gheCol} >GHE</th>
                                    <th className={style.editCol} ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    responseDataFilter.map(obj => (
                                        <tr className={style.linhaTable} >
                                            <td>{obj.FUNCAO}</td>
                                            <td>{obj.GHE ? obj.GHE : '-'}</td>
                                            <td className={style.editCol} >
                                                {
                                                    user?.PERMISSIONS?.includes('can_edit_ghe') && (
                                                        <div
                                                            className={style.editContainer}
                                                            onClick={() => openEdit(obj.FUNCAO, obj.GHE)}
                                                        >
                                                            <MdModeEdit className={style.editIcon} />
                                                        </div>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
            <Overlay state={editMode} >
                <FormContainer closeFunc={closeEdit} >
                    <form onSubmit={handleSubmit} >
                        <FormComp
                            title="FUNCÃO"
                            type="text"
                            valor={info.funcao}
                            readOnly
                        />
                        <FormComp
                            title="GHE"
                            type="select"
                            options={[
                                ... new Set(responseData.map(item => item.GHE).sort())
                            ]}
                            valor={info.ghe}
                            changeFunc={(e) => setInfo({ ...info, ghe: e.target.value })}
                            required
                        />
                        <FormComp
                            type="submit"
                            valor="SALVAR"
                        />
                    </form>
                </FormContainer>
            </Overlay>
        </>
    )

}

export default GheFuncoes;