import style from './ExamesGHE.module.css';

import SmallLoading from "../../Utils/SmallLoading";
import FilterContainer from '../../Utils/FilterContainer';

import axios from 'axios';

import { GoTrash } from "react-icons/go";

import { useEffect, useState, useRef, useCallback } from "react";


const ExamesGHE = () => {

    const containerRef = useRef( null );

    const [ loading, setloading ] = useState( false );

    const [ linhas, setLinhas ] = useState( 100 );

    const [ responseValues, setResponseValues ] = useState([]);
    const [ responseValuesFilter, setResponseValuesFilter ] = useState([]);

    const [ obras, setObras ] = useState([]);
    const [ ghes, setGhes ] = useState([]);
    const [ asos, setAsos ] = useState([]);
    const [ exames, setExames ] = useState([]);

    const [ obrasVal, setObrasVal ] = useState([]);
    const [ ghesVal, setGhesVal ] = useState([]);
    const [ asosVal, setAsosVal ] = useState([]);
    const [ examesVal, setExamesVal ] = useState([]);

    const handleFilter = useCallback(() => {

        setResponseValuesFilter(
            responseValues.filter(( item ) => {

                const obraFilter = !obrasVal.length || obrasVal.some( obj => ( item.centro_custo || '' ).includes( obj.value ))
                const gheFilter = !ghesVal.length || ghesVal.some( obj => ( item.ghe || '' ).includes( obj.value ))
                const asoFilter =!asosVal.length || asosVal.some( obj => ( item.aso || '' ).includes( obj.value ))
                const exameFilter =!examesVal.length || examesVal.some( obj => ( item.exame || '' ).includes( obj.value ))

                return obraFilter && gheFilter && asoFilter && exameFilter;

            })
        )

    }, [ obrasVal, ghesVal, asosVal, examesVal ])

    useEffect(() => {

        handleFilter()

    }, [ handleFilter ])

    const verifyHeight = () => {

        const container = containerRef.current;

        if (container.scrollTop + container.clientHeight >= container.scrollHeight){

            setLinhas( prev => prev + 100 )

        }

    }
    
    const getValues = async () => {

        setloading( true );

        try{

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/InfoPanel/GHE_Exames/', { withCredentials: true })
            console.log( data );

            setResponseValues( data );
            setResponseValuesFilter( data );

            setObras([ ...new Set( data.map( obj => obj.centro_custo ).sort())])
            setGhes([...new Set( data.map( obj => obj.ghe ).sort())])
            setAsos([...new Set( data.map( obj => obj.aso ).sort())])
            setExames([...new Set( data.map( obj => obj.exame ).sort())])

        }catch( e ){

            console.error( e );

        }finally{

            setloading( false );

        }

    }

    useEffect(() => {

        getValues();

    }, [])

    return(
        <>
            { loading 
                ? <SmallLoading />
                :
                <>
                    <div className={ style.headTitle } >
                        <p className={ style.title } >EXAMES x GHE</p>
                        <button className={ style.btnNovo } > NOVO </button>
                    </div>
                    <div className={ style.containerFilters } >
                        <FilterContainer
                            type="select"
                            placeholder="CENTRO CUSTO..."
                            options={ obras }
                            optType="obj"
                            valor={ obrasVal }
                            changeFunc={ ( selected ) => setObrasVal( selected ) }
                        />
                        <FilterContainer
                            type="select"
                            placeholder="GHE..."
                            options={ ghes }
                            optType="obj"
                            valor={ ghesVal }
                            changeFunc={ ( selected ) => setGhesVal( selected ) }
                        />
                        <FilterContainer
                            type="select"
                            placeholder="ASO..."
                            options={ asos }
                            optType="obj"
                            valor={ asosVal }
                            changeFunc={ ( selected ) => setAsosVal( selected ) }
                        />
                        <FilterContainer
                            type="select"
                            placeholder="EXAME..."
                            options={ exames }
                            optType="obj"
                            valor={ examesVal }
                            changeFunc={ ( select ) => setExamesVal( select ) }
                        />
                    </div>
                    <div className={ style.containerTable } ref={containerRef} onScroll={ verifyHeight } >
                        <table>
                            <thead>
                                <tr>
                                    <th>CENTRO CUSTO</th>
                                    <th>GHE</th>
                                    <th>ASO</th>
                                    <th>EXAME</th>
                                    <th className={ style.delCol } ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    responseValuesFilter.slice(0 , linhas).map( ( obj, index ) => (
                                        <tr key={ index } >
                                            <td>{ obj.centro_custo }</td>
                                            <td>{ obj.ghe }</td>
                                            <td>{ obj.aso }</td>
                                            <td>{ obj.exame }</td>
                                            <td className={ style.delCol } >
                                                <div className={ style.delContainer } >
                                                    <GoTrash className={ style.delIcon } />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    )

}

export default ExamesGHE;