import style from './FilterContainer.module.css';

import Select, { components } from 'react-select';

const FilterContainer = ({ type, placeholder, options, optType, changeFunc, valor }) => {

    const valores = optType === 'obj'
        ? options.map(opt => ({ value: opt, label: opt }))
        : options

    const ValueContainer = ({ children, ...props }) => {

        let [values, input] = children
        if (Array.isArray(values)) {

            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`

        }

        return (

            <components.ValueContainer {...props} >
                {values}
                {input}
            </components.ValueContainer>

        )

    }

    return (
        <div className={style.container} >
            {
                type === 'select' ? (

                    <Select
                        placeholder={placeholder}
                        options={valores}
                        isClearable
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ ValueContainer }}
                        onChange={(selected) => changeFunc(selected)}
                    />

                ) : (

                    <input
                        type={type}
                        placeholder={placeholder}
                        onChange={(e) => {

                            let valorInput = e.target.value

                            if( type === 'text' ){

                                valorInput = valorInput.toUpperCase();

                            }

                            changeFunc( valorInput )
                        }}
                        value={valor}
                    />

                )
            }
        </div>
    )

}

export default FilterContainer;