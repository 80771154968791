import style from './Filters.module.css';

const Filters = ({ columns, children }) => {
    return (
        <div 
            className={style.container} 
            style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }} 
        >
            {children}
        </div>
    );
}

export default Filters;
