import './FolhaPagSquare.css'

import { formatarData } from '../../Funcoes/FormatarData';
import { formatValorMoney } from '../../Funcoes/FormatValorMoney';
import { MdDelete } from "react-icons/md";
import { useState, useEffect, useRef } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import Tabela from '../Tabela';

const FolhaPagSquare = ({ dados, usuario, deleteTableRow }) => {

    const [idDel, setIdDel] = useState(null);
    const [origemDel, setOrigemDel] = useState(null);
    const [dataAtual, setDataAtual] = useState(null);
    const [tempId, setTempId] = useState(null);

    const [readyToPush, setReadyToPush] = useState(false);

    const delRow = (id, origem, temp_id) => {

        setIdDel(id);
        setOrigemDel(origem);
        setTempId(temp_id)

        let data = new Date();
        setDataAtual(format(data, "yyyy-MM-dd HH:mm:ss"))

        setReadyToPush(true);

    }

    useEffect(() => {

        if (readyToPush) {

            const deleteRow = async () => {

                try {

                    const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/DelPagRow/', {
                        id: idDel,
                        origem: origemDel,
                        data_atual: dataAtual,
                        user: usuario
                    })

                    if (data.STATUS === "SUCCESS") {

                        deleteTableRow(tempId)

                    }

                } catch (error) {

                    console.log(error)

                }

            }

            deleteRow();

            setReadyToPush(false);

        }

    }, [readyToPush])

    const tabelaRef = useRef(null);

    const [linhasTabela, setLinhasTabela] = useState(100);

    useEffect(() => {

        const element = tabelaRef.current;

        if (!element) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = element;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setLinhasTabela((prev) => prev + 100);
            }

        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <Tabela ref={tabelaRef} >
            <thead>
                <tr>
                    <th>VALOR</th>
                    <th>MOTIVO</th>
                    <th>MATRICULA</th>
                    <th>COLABORADOR</th>
                    <th>DATA</th>
                    <th>CENTRO CUSTO</th>
                    <th>OBSERVAÇÕES</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {dados.slice( 0, linhasTabela ).map((obj) => (

                    <tr>

                        <td>R$ {formatValorMoney(obj.valor)}</td>
                        <td>{obj.motivo}</td>
                        <td>{obj.matricula}</td>
                        <td>{obj.colaborador}</td>
                        <td>{formatarData(obj.data)}</td>
                        <td>{obj.centro_custo}</td>
                        <td>{obj.observacoes ? obj.observacoes : ' - '}</td>
                        <td>{obj.origem !== 'BENEFICIOS' ?
                            <MdDelete className='delRow' onClick={() => { delRow(obj.id, obj.origem, obj.temp_id) }} />
                            :
                            '-'
                        }</td>

                    </tr>

                ))}
            </tbody>
        </Tabela>
    )

}

export default FolhaPagSquare;