import styles from './HomeBody.module.css';

import { useState, useEffect, useRef, useContext } from 'react';
import Select, { components } from 'react-select';
import { formatCPF } from '../../Funcoes/FormatCPF';
import { formatDate } from '../../Funcoes/FormatData';
import { AuthContext } from '../../Auth/AuthProvider';

import Tabela from '../Tabela';

const HomeBody = ({ dados, funcoes, encarregados, obras, contratos, onFilter, loading }) => {

    const { user } = useContext( AuthContext );

    const navigate = (matricula) => {

        if( user.PERMISSIONS.includes('rh_role') || user.PERMISSIONS.includes('adm_role') ){

            window.open(`/Colaborador/${matricula}`, '_blank');

        }

    }

    const tabelaRef = useRef(null);

    const total = dados.length;
    const ativos = dados.filter(obj => obj.STATUS === "ATIVO").length;
    const desligados = dados.filter(obj => obj.STATUS === "DESLIGADO").length;
    const afastados = dados.filter(obj => obj.STATUS === "AFASTADO").length;
    const ferias = dados.filter(obj => obj.STATUS === "FERIAS").length;
    const diretos = dados.filter(obj => obj.MAOOBRA === "D").length;
    const indiretos = dados.filter(obj => obj.MAOOBRA === "I").length;

    const [linhas, setLinhas] = useState(100)

    const [nomeColab, setNomeColab] = useState('')
    const [matColab, setMatColab] = useState('')

    const [selectedFunc, setSelectedFunc] = useState([])
    const [selectedEnc, setSelectedEnc] = useState([])
    const [selectedObra, setSelectedObra] = useState([])
    const [selectedContrato, setSelectedContrato] = useState([])
    const [selectedStatus, setSelectedStatus] = useState([])
    const [selectedMaoobra, setSelectedMaoobra] = useState([])

    const handleChange = (selected, name) => {

        switch (name) {

            case 'funcao':
                setSelectedFunc(selected);
                break;
            case 'encarregado':
                setSelectedEnc(selected);
                break;
            case 'obra':
                setSelectedObra(selected);
                break;
            case 'contrato':
                setSelectedContrato(selected);
                break;
            case 'status':
                setSelectedStatus(selected);
                break;
            case 'maoobra':
                setSelectedMaoobra(selected);
                break;
            default:
                console.error("Invalid name");

        }

    }

    useEffect(() => {

        onFilter(nomeColab, matColab, selectedFunc, selectedEnc, selectedObra, selectedContrato, selectedStatus, selectedMaoobra)

    }, [nomeColab, matColab, selectedFunc, selectedEnc, selectedObra, selectedContrato, selectedStatus, selectedMaoobra])

    useEffect(() => {

        const element = tabelaRef.current;

        if (!element) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = element;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setLinhas((prev) => prev + 100);
            }

        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };

    }, []);

    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`;
        }
        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };

    return (
        <div className={styles.homeBody} >
            <div className={styles.homeHeader} >

                <div className={styles.filtersCont} >

                    <div className={styles.containerFilter} >
                        <input
                            type='text'
                            placeholder='COLABORADOR...'
                            value={nomeColab}
                            onChange={(e) => { setNomeColab(e.target.value) }}
                        />
                    </div>
                    <div className={styles.containerFilter} >
                        <input
                            type='text'
                            placeholder='MATRICULA...'
                            value={matColab}
                            onChange={(e) => { setMatColab(e.target.value) }}
                        />
                    </div>
                    <div className={styles.containerFilter} >
                        <Select
                            className={styles.customSelect}
                            placeholder='FUNÇÃO...'
                            components={{ ValueContainer }}
                            options={funcoes.map(funcao => ({ value: funcao, label: funcao }))}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            value={selectedFunc}
                            onChange={(selected) => { handleChange(selected, 'funcao') }}
                        />
                    </div>
                    <div className={styles.containerFilter} >
                        <Select
                            className={styles.customSelect}
                            placeholder='ENCARREGADO...'
                            components={{ ValueContainer }}
                            options={encarregados.map(enc => ({ value: enc, label: enc }))}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            value={selectedEnc}
                            onChange={(selected) => { handleChange(selected, 'encarregado') }}
                        />
                    </div>

                    <div className={styles.containerFilter} >
                        <Select
                            className={styles.customSelect}
                            placeholder='CENTRO CUSTO...'
                            components={{ ValueContainer }}
                            options={obras.map(obra => ({ value: obra, label: obra }))}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            value={selectedObra}
                            onChange={(selected) => { handleChange(selected, 'obra') }}
                        />
                    </div>
                    <div className={styles.containerFilter} >
                        <Select
                            className={styles.customSelect}
                            placeholder='CONTRATO...'
                            components={{ ValueContainer }}
                            options={contratos.map(cont => ({ value: cont, label: cont }))}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            value={selectedContrato}
                            onChange={(selected) => { handleChange(selected, 'contrato') }}
                        />
                    </div>
                    <div className={styles.containerFilter} >
                        <Select
                            className={styles.customSelect}
                            placeholder='STATUS...'
                            components={{ ValueContainer }}
                            options={[
                                { value: 'ATIVO', label: 'ATIVO' },
                                { value: 'DESLIGADO', label: 'DESLIGADO' },
                                { value: 'AFASTADO', label: 'AFASTADO' },
                                { value: 'FERIAS', label: 'FÉRIAS' },
                            ]}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            value={selectedStatus}
                            onChange={(selected) => { handleChange(selected, 'status') }}
                        />
                    </div>
                    <div className={styles.containerFilter} >
                        <Select
                            className={styles.customSelect}
                            placeholder='MÃO DE OBRA...'
                            components={{ ValueContainer }}
                            options={[
                                { value: 'D', label: 'DIRETA' },
                                { value: 'I', label: 'INDIRETA' }
                            ]}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            value={selectedMaoobra}
                            onChange={(selected) => { handleChange(selected, 'maoobra') }}
                        />
                    </div>

                </div>
                <div className={styles.statsCont} >

                    <div className={styles.statsSquare} >
                        <p>COLABORADORES: {total}</p>
                        <p>ATIVOS: {ativos}&nbsp;&nbsp;&nbsp;DESLIGADOS: {desligados}</p>
                        <p>AFASTADOS: {afastados}&nbsp;&nbsp;&nbsp;FÉRIAS: {ferias}</p>
                        <p>DIRETO: {diretos}&nbsp;&nbsp;&nbsp;INDIRETO: {indiretos}</p>
                    </div>

                </div>

            </div>


            <Tabela ref={tabelaRef} loading={ loading } >
                <thead>
                    <tr>
                        <th></th>
                        <th>COLABORADOR</th>
                        <th>CPF</th>
                        <th>MATRICULA</th>
                        <th>FUNÇÃO</th>
                        <th>ENCARREGADO</th>
                        <th>ADMISSÃO</th>
                        <th>DEMISSÃO</th>
                        <th>DIAS TRAB.</th>
                        <th>CENTRO CUSTO</th>
                        <th>CONTRATO</th>
                        <th>MO</th>
                        <th>STATUS</th>
                    </tr>
                </thead>
                <tbody>
                    {dados.slice(0, linhas).map(obj => (
                        <tr
                            key={obj.MATRICULA}
                            onClick={() => { navigate(obj.MATRICULA) }}
                        >
                            <td>
                                <div className={obj.DADOS === "PENDENTE" ? styles.colabPend : styles.colabOk} ></div>
                            </td>
                            <td>{obj.NOME}</td>
                            <td>{formatCPF(obj.CPF)}</td>
                            <td>{obj.MATRICULA}</td>
                            <td>{obj.FUNCAO}</td>
                            <td>{obj.IMEDIATO ? obj.IMEDIATO : '-'}</td>
                            <td>{formatDate(obj.ADMISSAO)}</td>
                            <td>{obj.DEMISSAO !== "        " ? formatDate(obj.DEMISSAO) : '-'}</td>
                            <td>{obj.DIAS_TRAB}</td>
                            <td>{obj.CENTRO_CUSTO}</td>
                            <td>{obj.CONTRATO ? obj.CONTRATO : '-'}</td>
                            <td>{obj.MAOOBRA}</td>
                            <td>{obj.STATUS}</td>
                        </tr>
                    ))}
                </tbody>
            </Tabela>
        </div>

    )

}

export default HomeBody;