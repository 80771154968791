import style from './SideMenu.module.css';
import { FiLogOut } from "react-icons/fi";
import { AuthContext } from '../../Auth/AuthProvider';
import { useContext } from 'react';

const SideMenu = ({ children }) => {

    const { logout } = useContext(AuthContext)

    return (
        <div className={style.sideMenu} onClick={(e) => { e.stopPropagation() }} >

            <div className={style.container} >
                {children}
            </div>

            <div className={style.logoutContainer} >

                <div className={style.logoutBtn} onClick={logout} >
                    <div className={style.iconDiv} ><FiLogOut size={25} /></div>
                    <div className={style.textDiv} ><p>LOGOUT</p></div>
                </div>

            </div>
        </div>
    )

}

export default SideMenu;