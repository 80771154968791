import { useState } from 'react';
import style from './ContainerBtns.module.css';

import { IoIosArrowDown } from "react-icons/io";

const ContainerBtns = ({ children, title }) => {

    const [ openMenu, setOpenMenu ] = useState( false );

    const handleClick = () => {
        setOpenMenu(!openMenu);
    }

    return(
        <div className={ style.container } >
            <div className={ style.openBtn } onClick={ handleClick } >
                <p>{ title }</p>
                <div className={ openMenu ? style.containerArrowUp : style.containerArrowDown } >
                    <IoIosArrowDown className={ style.iconArrow } />
                </div>
            </div>
            
            { openMenu && children }
        </div>
    )

}

export default ContainerBtns;