import style from './Stats.module.css';

const Stats = ({ children }) => {

    return(
        <div className={ style.container } >
            { children }
        </div>
    )

}

export default Stats;