import style from './Register.module.css';

import { BsFillPersonVcardFill } from "react-icons/bs";
import { IoMdPerson } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import { FiLock } from "react-icons/fi";
import { useEffect, useState } from 'react';
import { formatCPF } from '../Funcoes/FormatCPF';
import axios from 'axios';

import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import { useNavigate } from 'react-router-dom';


const Register = () => {

    const [ loading, setLoading ] = useState( false );

    const navigate = useNavigate();

    const [cpfUser, setCpfUser] = useState('')
    const [nameUser, setNameUser] = useState('')
    const [emailUser, setEmailUser] = useState('')
    const [passwordUser, setPasswordUser] = useState('')

    const handleCpfChange = (e) => {
        const value = e.target.value;
        setCpfUser(formatCPF(value));
    };

    const getColab = async (cpf) => {

        try {

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetColabUser/', {
                cpf: cpf
            })

            console.log(data);
            setNameUser(data.NOME + ' ' + data.SOBRENOME)

        } catch (e) {

            console.log(e);

        }

    }

    useEffect(() => {

        if (cpfUser.length === 14) {

            getColab(cpfUser);

        } else {

            setNameUser('');

        }

    }, [cpfUser])

    const [ passView, setPassView ] = useState( false );

    const showPass = () => {

        setPassView( !passView );

    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if( nameUser === '' ){

            alert('Selecione um CPF válido')
            return;

        }

        setLoading( true );

        try{

            await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/RegisterUser/', {
                nome: nameUser,
                cpf: cpfUser,
                email: emailUser,
                senha: passwordUser
            })

            alert('Usuario cadastrado com sucesso!!!');
            navigate( '/Login' )

        }catch( e ){

            alert( e.response.data.message )

        }finally{

            setLoading( false );

        }

    }

    return (
        <div className={style.background} >
            <div className={style.container} >
                <div className={style.imgLeftContainer} >
                    <div className={style.imgContainer} >
                        <img src={'/Images/PREPARO_DE_MADEIRA.jpg'} alt='fundoPalmont' />
                    </div>
                </div>
                <div className={style.rightContent} >
                    <img className={style.logoPalmont} src='/Images/Logo.png' alt='palmontLogo' />
                    <form onSubmit={ handleSubmit } >
                        <div className={style.colabData} >
                            <div className={style.inputData} >
                                <div className={style.iconInput} >
                                    <BsFillPersonVcardFill />
                                </div>
                                <input
                                    type='text'
                                    placeholder='CPF:'
                                    onChange={handleCpfChange}
                                    value={cpfUser}
                                    required
                                    aria-label='CPF'
                                />
                            </div>
                            <div className={style.inputData} >
                                <div className={style.iconInput} >
                                    <IoMdPerson />
                                </div>
                                <input
                                    type='text'
                                    placeholder='Nome:'
                                    readOnly
                                    value={nameUser}
                                    required
                                />
                            </div>
                        </div>
                        <div className={style.inputData} >
                            <div className={style.iconInput} >
                                <HiOutlineMail />
                            </div>
                            <input
                                type='email'
                                placeholder='E-mail:'
                                value={emailUser}
                                onChange={(e) => setEmailUser(e.target.value)}
                                required
                            />
                        </div>
                        <div className={style.inputData} >
                            <div className={style.iconInput} >
                                <FiLock />
                            </div>
                            <input
                                type={ passView ? 'text' : 'password' }
                                placeholder='Senha:'
                                value={passwordUser}
                                onChange={(e) => setPasswordUser(e.target.value)}
                                required
                            />
                            <div
                                className={style.showPass}
                                onClick={showPass}
                            >
                                {passView ? (<IoEye />) : (<IoMdEyeOff />)}
                            </div>
                        </div>
                        <button className={style.saveBtn} disabled={ loading } >
                            { loading ? 'SALVANDO...' : 'SALVAR' }
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )

}

export default Register;