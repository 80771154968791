import HomeBody from "../Componentes/HomeBody";
import NavBar from "../Componentes/NavBar";
import Overlay from "../Componentes/Overlay";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";

import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ContainerBtns from "../Componentes/ContainerBtns";
import { toast, ToastContainer } from "react-toastify";

import { AuthContext } from "../Auth/AuthProvider";

const NewHome = () => {

    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [loadingRelat, setLoadingRelat] = useState(false);

    const [overlay, setOverlay] = useState(false);

    const [colabs, setColabs] = useState([]);
    const [colabsFilter, setColabsFilter] = useState([]);

    const funcoes = [...new Set(colabs.map(item => item.FUNCAO).filter(FUNCAO => FUNCAO).sort())]
    const obras = [...new Set(colabs.map(item => item.CENTRO_CUSTO).filter(CENTRO_CUSTO => CENTRO_CUSTO).sort())]
    const encarregados = [...new Set(colabs.map(item => item.ENCARREGADO).filter(ENCARREGADO => ENCARREGADO).sort())]
    const contratos = [...new Set(colabs.map(item => item.CONTRATO).filter(CONTRATO => CONTRATO).sort())]

    const filterDados = useCallback((filterName, filterMat, filterFunc, filterEnc, filterObra, filterCont, filterStatus, filterMaoObra) => {

        const filtered = colabs.filter(item => {

            const name_filter = !filterName || item.NOME.toUpperCase().includes(filterName.toUpperCase());
            const mat_filter = !filterMat || item.MATRICULA.includes(filterMat)

            const func_filter = filterFunc.length === 0 || filterFunc.some(func => item.FUNCAO.includes(func.value));
            const enc_filter = filterEnc.length === 0 || filterEnc.some(enc => (item.ENCARREGADO || '').includes(enc.value));
            const obra_filter = filterObra.length === 0 || filterObra.some(obra => item.CENTRO_CUSTO.includes(obra.value));
            const cont_filter = filterCont.length === 0 || filterCont.some(cont => (item.CONTRATO || '').includes(cont.value));
            const stat_filter = filterStatus.length === 0 || filterStatus.some(stat => item.STATUS.includes(stat.value));
            const mao_filter = filterMaoObra.length === 0 || filterMaoObra.some(mao => item.MAOOBRA.includes(mao.value));

            return name_filter && mat_filter && func_filter && enc_filter && obra_filter && cont_filter && stat_filter && mao_filter;

        })

        setColabsFilter(filtered);

    }, [colabs])

    useEffect(() => {

        setLoading(true);

        const getColabs = async () => {

            try {

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetHomeColabs/');

                setColabs(data);
                setColabsFilter(data);

            } catch (error) {

                console.log(error);

            } finally {

                setLoading(false);

            }

        }

        getColabs();

    }, [])

    const generateRPE = async () => {

        setLoadingRelat(true);

        try {
            const response = await axios.post(
                'https://sistemaintegrado.palmont.com.br/Painel/API/GenerateRelatAdm/',
                {
                    //nomeFilter: nome,
                    //matriculaFilter: matricula,
                    //funcaoFilter: funcao,
                    //obraFilter: obra,
                    //maoObraFilter: maoObra,
                    //statusFilter: status
                },
                {
                    responseType: 'blob',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'relatorio.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {

            console.error("Erro ao gerar o relatório:", error);
            alert("Ocorreu um erro ao gerar o relatório. Por favor, tente novamente.");

        } finally {

            setLoadingRelat(false);

        }

    }

    const showOverlay = () => {

        setOverlay(true);

    }

    const handleClick = (to, permission) => {

        if (permission) {

            navigate(to);

        } else {

            toast.error('Você não possui permissão para acessar esse módulo')

        }

    }

    console.log(user)

    return (
        <>
            <NavBar
                title="HOME"
                changeState={showOverlay}
            />
            <HomeBody
                dados={colabsFilter}
                funcoes={funcoes}
                obras={obras}
                encarregados={encarregados}
                contratos={contratos}
                onFilter={filterDados}
                loading={loading}
            />
            <Overlay
                state={overlay}
                changeState={setOverlay}
            >
                <SideMenu>
                    {
                        (user.PERMISSIONS.includes('adm_role') || user.PERMISSIONS.includes('ssma_role')) &&
                        <ContainerBtns title="SSMA" >
                            <SideMenuBtn
                                button="AUDITORIA INTERNA"
                                clickFunc={() => handleClick('/AuditoriaInterna', user.PERMISSIONS.includes('can_access_audit'))}
                            />
                            <SideMenuBtn
                                button="DESVIOS"
                                clickFunc={() => { window.location.href = 'https://sistemaintegrado.palmont.com.br/Painel/Desvios/' }}
                            />
                            <SideMenuBtn
                                button="INSPEÇÃO DE CAMPO"
                                clickFunc={() => { window.location.href = 'https://sistemaintegrado.palmont.com.br/Painel/Inspecao/' }}
                            />
                            <SideMenuBtn
                                button="INSPEÇÃO AMBIENTAL"
                                clickFunc={() => { window.location.href = 'https://sistemaintegrado.palmont.com.br/Painel/InspecaoMA/' }}
                            />
                            <SideMenuBtn
                                button="PAINEL DE CONTROLE"
                                clickFunc={() => handleClick('/InfoPanel', true )}
                            />
                            <SideMenuBtn
                                button="RAC"
                                clickFunc={() => handleClick('/RAC', user.PERMISSIONS.includes('can_access_rac'))}
                            />
                            <SideMenuBtn
                                button="TREINAMENTOS"
                                clickFunc={() => handleClick('/Treinamentos', true)}
                            />
                        </ContainerBtns>
                    }
                    {
                        (user.PERMISSIONS.includes('adm_role') || user.PERMISSIONS.includes('rh_role')) &&
                        <ContainerBtns title="ADM" >
                            <SideMenuBtn
                                button="AFASTAMENTOS"
                                clickFunc={() => handleClick('/Afastamentos', true)}
                            />
                            <SideMenuBtn
                                button="ATENDIMENTOS"
                                clickFunc={() => { window.location.href = 'https://sistemaintegrado.palmont.com.br/Painel/Atendimento/' }}
                            />
                            <SideMenuBtn
                                button="CONTROLE DE EXPERIÊNCIA"
                                clickFunc={() => handleClick('/Recisoes', true)}

                            />
                            <SideMenuBtn
                                button="EXAMES OCUPACIONAIS"
                                clickFunc={() => handleClick('/ExamOcup', true)}
                            />
                            {
                                user.PERMISSIONS.includes('can_access_folha_pag') &&
                                <SideMenuBtn
                                    button="FOLHA DE PAGAMENTO"
                                    clickFunc={() => handleClick('/FolhaPag', user.PERMISSIONS.includes('can_access_folha_pag'))}
                                />
                            }
                            <SideMenuBtn
                                button="GERAR RELATÓRIO"
                                isLoading={loadingRelat}
                                clickFunc={generateRPE}
                            />
                            <SideMenuBtn
                                button="PROCESSO ADM/TRANSF"
                                clickFunc={() => handleClick('/ProcessoAdm', true)}
                            />
                            <SideMenuBtn
                                button="REPUBLICAS"
                                clickFunc={() => handleClick('/Republicas', true)}
                            />
                        </ContainerBtns>
                    }
                </SideMenu>
            </Overlay>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
                style={{
                    zIndex: '99999'
                }}
            />
        </>
    );
}

export default NewHome;