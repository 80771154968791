import './AdmissaoSquare.css';

import { formatCPF } from '../../Funcoes/FormatCPF';
import { formatarData } from '../../Funcoes/FormatarData';
import { formatTelefone } from '../../Funcoes/FormatTelefone';
import Tabela from '../Tabela';
import { useState, useEffect, useRef } from 'react';

const AdmissaoSquare = ({ tabelaValues, openToEdit }) => {

    const handleClick = (value) => {

        console.log(value)
        openToEdit(value)

    }

    const [linhasTabela, setLinhasTabela] = useState(100);

    const tabelaRef = useRef(null);

    useEffect(() => {

        const element = tabelaRef.current;

        if (!element) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = element;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setLinhasTabela((prev) => prev + 100);
            }

        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };

    }, [])

    return (
        <Tabela ref={ tabelaRef } >
            <thead>
                <tr>
                    <th>ORIGEM CC</th>
                    <th>DESTINO CC</th>
                    <th>NOME</th>
                    <th>CPF</th>
                    <th>TELEFONE</th>
                    <th>FUNÇÃO</th>
                    <th>SALÁRIO</th>
                    <th>STATUS</th>
                    <th>DATA</th>
                    <th>MOTIVO</th>
                    <th>DESISTÊNCIA</th>
                </tr>
            </thead>

            <tbody>
                {tabelaValues.slice( 0, linhasTabela ).map((obj) => (
                    <tr
                        key={obj.identifier}
                        className={obj.ordem_item === 1 ? 'admisAtras' : ''}
                        onClick={() => handleClick(obj)}
                    >
                        <td>{obj.cc_origem ? obj.cc_origem : '-'}</td>
                        <td>{obj.cc_destino ? obj.cc_destino : '-'}</td>
                        <td>{obj.nome}</td>
                        <td>{obj.cpf ? formatCPF(obj.cpf) : '-'}</td>
                        <td>{obj.telefone ? formatTelefone(obj.telefone) : '-'}</td>
                        <td>{obj.funcao ? obj.funcao : '-'} </td>
                        <td>{obj.salario ? `R$ ${obj.salario}` : '-'}</td>
                        <td>{obj.status}</td>
                        <td>{obj.data ? formatarData(obj.data) : '-'}</td>
                        <td>{obj.motivo}</td>
                        <td>{obj.desistente === '*' ? 'SIM' : '-'} </td>
                    </tr>
                ))}
            </tbody>
        </Tabela>
    )

}

export default AdmissaoSquare;