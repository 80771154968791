import NavBar from "../Componentes/NavBar"
import AdmissaoBody from "../Componentes/AdmissaoBody"
import 'react-toastify/dist/ReactToastify.css';

import Overlay from "../Componentes/Overlay"
import SideMenu from "../Componentes/SideMenu"
import SideMenuBtn from "../Componentes/SideMenuBtn"

import { useState, useEffect, useCallback } from "react"
import { ToastContainer } from 'react-toastify';
import axios from "axios"
import SquareTransfer from "../Componentes/SquareTransfer"
import SquareAdmis from "../Componentes/SquareAdmis"

const Admissao = () => {

    const [overlaySidebar, setOverlaySidebar] = useState(false);

    const openSidebar = () => {

        setOverlaySidebar(true);

    }
    const closeSidebar = () => {

        setOverlaySidebar(false);

    }

    const [overlayTransfer, setOverlayTransfer] = useState(false);
    const [overlayAdmis, setOverlayAdmis] = useState(false);

    const openTransfer = () => {

        setOverlaySidebar(false);
        setOverlayTransfer(true);

    }

    const openAdmis = () => {

        setOverlaySidebar(false);
        setOverlayAdmis(true);

    }

    const closeTransfer = () => {

        setOverlayTransfer(false);
        setEditItem(false);

    }

    const closeAdmis = () => {

        setOverlayAdmis(false);
        setEditItem(false);

    }

    const [objEdit, setObjEdit] = useState(null)
    const [editItem, setEditItem] = useState(false)

    const openToEdit = (obj) => {

        setObjEdit(obj)
        setEditItem(true)
        obj.motivo === 'ADMISSAO' ? openAdmis() : openTransfer()

    }

    const [admissions, setAdimissions] = useState([]);
    const [admissionsFilter, setAdimissionsFilter] = useState([]);

    const fetch_admissions = async () => {

        const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetAdmissions/');

        setAdimissions(data);
        setAdimissionsFilter(data);

        console.log( data );

    }

    const [obrasAtivas, setObrasAtivas] = useState([]);
    const [functions, setFunctions] = useState([]);

    const getObrasAtivas = async () => {

        try {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasAtivas/')

            const obrasValues = data.map((obj) => (
                obj.OBRA
            ))

            setObrasAtivas(obrasValues)

        } catch (error) {

            console.log(error)

        }

    }

    const getFunctions = async () => {

        try {

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/GetFunctions/')
            setFunctions(data)

        } catch (error) {

            console.log(error)

        }

    }

    useEffect(() => {

        fetch_admissions();
        getObrasAtivas();
        getFunctions();

    }, [])

    const handleFilter = useCallback((nameFilter, cpfFilter, funcaoFilter, origemFilter, destinoFilter, motivoFilter, desistFilter, statusFilter, startDateFilter, endDateFilter) => {

        const cpfFormated = cpfFilter.replace(/\D/g, '');
        const statusValue = statusFilter && typeof statusFilter === 'object' ? statusFilter.value : undefined;
        const desistValue = desistFilter && typeof desistFilter === 'object' ? desistFilter.value : undefined;
        const motivoValue = motivoFilter && typeof motivoFilter === 'object' ? motivoFilter.value : undefined;

        const filtered = admissions.filter(item => {

            const name = !nameFilter || item.nome?.includes(nameFilter);
            const cpf = !cpfFilter || item.cpf?.includes(cpfFormated);
            const funcao = funcaoFilter.length === 0 || funcaoFilter.some(func => item.funcao?.includes(func.value));
            const origem = origemFilter.length === 0 || origemFilter.some(obra => (item.cc_origem || '').includes(obra.value));
            const destino = destinoFilter.length === 0 || destinoFilter.some(obra => item.cc_destino?.includes(obra.value));
            const motivo = motivoValue === undefined || item.motivo === motivoValue;
            const desist = desistValue === undefined || (desistValue === 'SIM'
                ? (item.desistente === '*')
                : (item.desistente === null)
            );
            const status = statusValue === undefined || (statusValue === 'EM ANDAMENTO'
                ? (item.status !== 'ENTROU NO SITE' && item.desistente === null)
                : (item.status === 'ENTROU NO SITE' || item.desistente !== null)
            );
            const start_date = !startDateFilter || item.data >= startDateFilter;
            const end_date = !endDateFilter || item.data <= endDateFilter;


            return name && cpf && funcao && origem && destino && motivo && desist && status && start_date && end_date;

        })

        setAdimissionsFilter(filtered);

    }, [admissions])

    return (

        <>

            <NavBar setSideBar={openSidebar} title="PROC. ADMISSIONAL / TRANFERÊNCIA" />

            <AdmissaoBody
                tabelaValues={admissionsFilter}
                tabelaForFilters={admissions}
                filterTable={handleFilter}
                openToEdit={openToEdit}
            />

            <Overlay
                state={overlaySidebar}
                changeContent={closeSidebar}
            >
                <SideMenu>
                    <SideMenuBtn
                        button="NOVO COLABORADOR"
                        clickFunc={openAdmis}
                    />
                    <SideMenuBtn
                        button="TRANSFERIR COLABORADOR"
                        clickFunc={openTransfer}
                    />
                </SideMenu>
            </Overlay>

            <Overlay state={overlayTransfer}>
                <SquareTransfer
                    close={closeTransfer}
                    getNewValues={fetch_admissions}
                    obras={obrasAtivas}
                    functions={functions}
                    objEdit={objEdit}
                    editing={editItem}
                />
            </Overlay>

            <Overlay state={overlayAdmis} >
                <SquareAdmis
                    close={closeAdmis}
                    getNewValues={fetch_admissions}
                    obras={obrasAtivas}
                    functions={functions}
                    objEdit={objEdit}
                    editing={editItem}
                />
            </Overlay>

            <ToastContainer
                style={{
                    zIndex: 99999
                }}
                autoClose={3000}
                position="top-center"
                theme="dark"
            />

        </>

    )

}

export default Admissao