import style from './RacForm.module.css';
import FormComp from '../../FormComp';

import axios from 'axios';
import Cookies from 'js-cookie';

import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

import { LuLoaderCircle } from "react-icons/lu";
import { MdOutlineClose } from "react-icons/md";

import { getItensByNorma } from '../../../Funcoes/GetItensByNorma';

const RacForm = ({ closeForm, updateItens }) => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true)
    const [loadingCount, setLoadingCount] = useState(4)

    const [obras, setObras] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [lideres, setLideres] = useState([]);

    const [formValues, setFormValues] = useState({
        centro_custo: '',
        contrato: '',
        rnc: '',
        tipo: '',
        norma: '',
        item_norma: '',
        setor: '',
        resp_setor: '',
        fato: '',
        evidencia: '',
        correcao: '',
        anal_causa_raiz: '',
        what: '',
        why: '',
        where: '',
        when: '',
        who: '',
        how: '',
        how_much: '',
        data_realizada: '',
        observacoes: '',
        eficacia: '',
        aprovado_por: '',
        data_encerramento: ''
    })

    const [ anexos, setAnexos ] = useState([])

    const setores = [
        "ADM",
        "ALMOXARIFADO/MATERIAIS",
        "ALTA DIREÇÃO",
        "ANDAIMES",
        "COMERCIAL",
        "CONTABILIDADE",
        "CONTROLE DE OBRAS",
        "ELÉTRICA",
        "ENGENHARIA E ORÇAMENTOS",
        "FABRICAÇÃO (PIPE SHOP)",
        "FINANCEIRO",
        "FISCAL",
        "IÇAMENTO E MOVIMENTAÇÃO DE CARGA",
        "INSTRUMENTAÇÃO",
        "ISOLAMENTO TÉRMICO",
        "JURÍDICO",
        "LOGÍSTICA",
        "MANUTENÇÃO MECÂNICA",
        "MECÂNICA",
        "PINTURA",
        "PLANEJAMENTO/GERENCIAMENTO DE OBRAS",
        "QUALIDADE",
        "RECURSOS HUMANOS",
        "SGI - SISTEMA DE GESTÃO INTEGRADO",
        "SSMA",
        "SUPRIMENTOS",
        "T.I.",
        "TUBULAÇÃO"
    ]

    const decreaseLoading = () => {

        setLoadingCount(prev => {

            if (prev > 0) {

                const newCount = prev - 1;
                if (newCount === 0) setLoading(false);
                return newCount;

            } else {

                return 0

            }

        })

    }

    useEffect(() => {

        if (id) {

            const getValues = async () => {

                try {

                    const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/RAC/GetRegister/index.php?id=' + id)

                    setFormValues(prev => ({

                        ...prev,
                        centro_custo: data.centro_custo || '',
                        contrato: data.contrato || '',
                        rnc: data.rnc || '',
                        tipo: data.tipo_nc || '',
                        norma: data.norma || '',
                        item_norma: data.item_norma || '',
                        setor: data.setor || '',
                        resp_setor: data.responsavel_setor || '',
                        fato: data.fato || '',
                        evidencia: data.evidencia || '',
                        correcao: data.correcao || '',
                        anal_causa_raiz: data.anal_causa_raiz || '',
                        what: data.what || '',
                        why: data.why || '',
                        where: data.where || '',
                        when: data.when || '',
                        who: data.who || '',
                        how: data.how || '',
                        how_much: data.how_much || '',
                        data_realizada: data.realizado || '',
                        observacoes: data.observacoes || '',
                        eficacia: data.eficaz || '',
                        aprovado_por: data.aprovado || '',
                        data_encerramento: data.encerramento || ''
                    }))

                    setAnexos( data.anexos )

                    console.log(data)

                } catch (error) {

                    console.log(error)

                } finally {

                    decreaseLoading();

                }

            }

            getValues();

        } else {

            decreaseLoading();

        }

    }, [])

    useEffect(() => {

        const getObras = async () => {

            try {

                const { data } = await axios.get("https://sistemaintegrado.palmont.com.br/Painel/API/GetObrasAtivas/")
                setObras(data.map(obj => obj.OBRA))

            } catch (error) {

                console.log(error)

            } finally {

                decreaseLoading();

            }

        }

        getObras();

    }, [id])

    useEffect(() => {

        const getContratos = async () => {

            try {

                const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetContratosObra/', {
                    centro_custo: formValues.centro_custo
                })

                setContratos(data.map(obj => obj.nome_contrato))

            } catch (error) {

                console.log(error)

            } finally {

                decreaseLoading();

            }

        }

        getContratos();

    }, [id, formValues.centro_custo])

    useEffect(() => {

        const getLideres = async () => {

            try {

                const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/assets/php/getLideres.php')
                setLideres(data.map(obj => obj.RA_NOMECMP))

            } catch (error) {

                console.error(error)

            } finally {

                decreaseLoading();

            }

        }

        getLideres();

    }, [])

    const handleChange = (field, e) => {

        let { value } = e.target;

        if (field === "how_much") {
            value = value.replace(/\D/g, ""); // Remove tudo que não for número
            value = value ? parseInt(value, 10) : 0; // Converte para inteiro (centavos)
            value = (value / 100).toFixed(2); // Formata para decimal
        }

        setFormValues(prev => ({
            ...prev,
            [field]: value,
            ...(field === "centro_custo" && { contrato: "" }),
            ...(field === "norma" && { item_norma: "" }),
        }));
    };


    const handleSubmit = async (e) => {

        e.preventDefault();

        const formData = new FormData();
        for (const key in formValues) {

            formData.append(key, formValues[key]);

        }

        if( selectedFiles ){

            selectedFiles.forEach( file => {

                formData.append( 'files[]', file )

            })

        }

        try {

            const url = id
                ? "https://sistemaintegrado.palmont.com.br/Painel/API/RAC/UpdateItem/index.php?id=" + id
                : "https://sistemaintegrado.palmont.com.br/Painel/API/RAC/SaveItem/"

            const method = 'post'

            const { data } = await toast.promise(
                axios({
                    method: method,
                    url: url,
                    data: formData,
                    headers: {
                        withCredentials: true,
                        'Content-Type': 'multipart/form-data'
                    }
                }),
                {
                    pending: 'SALVANDO INFORMAÇÕES',
                    success: 'INFORMAÇÕES SALVAS COM SUCESSO',
                    error: 'FALHA AO SALVAR AS INFORMAÇÕES'
                }
            )

            console.log(data);
            await updateItens();
            closeForm();

        } catch (error) {

            console.log(error);

        }

    }

    const [ selectedFiles, setSelectedFiles ] = useState([])

    const handleFileChange = ( e ) => {

        const files = e.target.files;
        const arrayFiles = Array.from( files )

        setSelectedFiles( arrayFiles );

    }

    return (
        <div className={style.container} >
            <div className={style.header} >
                {id ?
                    <p className={style.tituloForm} >EDITANDO ID {id}</p>
                    :
                    <p className={style.tituloForm} >GERANDO NOVA RAC</p>
                }
                <div
                    className={style.closeDiv}
                    onClick={closeForm}
                >
                    <MdOutlineClose className={ style.closeIcon } />
                </div>
            </div>

            {
                loading ?
                    <div className={style.loading} >
                        <div className={ style.loadingContainer } >
                            <div className={style.spinnerContainer} >
                                <LuLoaderCircle className={ style.spinner } />
                            </div>
                            <p>CARREGANDO...</p>
                        </div>
                    </div>
                    :
                    <div className={style.body} >
                        <form
                            onSubmit={handleSubmit}
                        >
                            <FormComp
                                title="CENTRO CUSTO"
                                type="select"
                                options={obras}
                                valor={formValues.centro_custo}
                                changeFunc={(e) => handleChange('centro_custo', e)}
                            />
                            <FormComp
                                title="CONTRATO"
                                type="select"
                                options={contratos}
                                valor={formValues.contrato}
                                changeFunc={(e) => handleChange('contrato', e)}
                            />
                            <FormComp
                                title="RNC"
                                type="text"
                                valor={formValues.rnc}
                                changeFunc={(e) => handleChange('rnc', e)}
                            />
                            <FormComp
                                title="TIPO"
                                type="select"
                                options={[
                                    "NC RECLAMAÇÃO DO CLIENTE",
                                    "NC INTERNA",
                                    "NC FORNECEDOR",
                                    "NC AUDITORIA EXTERNA ( CERTIFICADORA )",
                                    "NC AUDITORIA EXTERNA ( CLIENTES )",
                                    "RISCOS E OPORTUNIDADES"
                                ]}
                                valor={formValues.tipo}
                                changeFunc={(e) => handleChange('tipo', e)}
                            />
                            <FormComp
                                title="NORMA"
                                type="select"
                                options={[
                                    "ISO 9001:2015",
                                    "ISO 14001:2015",
                                    "ISO 45001:2018"
                                ]}
                                valor={formValues.norma}
                                changeFunc={(e) => handleChange('norma', e)}
                            />
                            <FormComp
                                title="ITEM"
                                type="select"
                                options={
                                    getItensByNorma(formValues.norma)
                                }
                                valor={formValues.item_norma}
                                changeFunc={(e) => handleChange('item_norma', e)}
                            />
                            <FormComp
                                title="SETOR"
                                type="select"
                                options={setores}
                                valor={formValues.setor}
                                changeFunc={(e) => handleChange('setor', e)}
                            />
                            <FormComp
                                title="RESPONSÁVEL PELO SETOR"
                                type="select"
                                options={lideres}
                                valor={formValues.resp_setor}
                                changeFunc={(e) => handleChange('resp_setor', e)}
                            />
                            <FormComp
                                title="FATO"
                                type="textarea"
                                valor={formValues.fato}
                                changeFunc={(e) => handleChange('fato', e)}
                            />
                            <FormComp
                                title="EVIDÊNCIA OBJETIVA"
                                type="textarea"
                                valor={formValues.evidencia}
                                changeFunc={(e) => handleChange('evidencia', e)}
                            />
                            <FormComp
                                title="CORREÇÃO IMEDIATA"
                                type="textarea"
                                valor={formValues.correcao}
                                changeFunc={(e) => handleChange('correcao', e)}
                                titleHover="OQUE É FEITO PARA RESOLVER ESSE PROBLEMA"
                            />
                            <FormComp
                                title="ANÁLISE DA CAUSA RAIZ"
                                type="textarea"
                                valor={formValues.anal_causa_raiz}
                                changeFunc={(e) => handleChange('anal_causa_raiz', e)}
                                titleHover="OQUE FALHOU NO SISTEMA PARA PERMITIR QUE ESSA NC OCORRA"
                            />
                            <FormComp
                                title="AÇÃO CORRETIVA - WHAT ( O QUE SERÁ FEITO ? )"
                                type="textarea"
                                valor={formValues.what}
                                changeFunc={(e) => handleChange('what', e)}
                                titleHover="OQUE É FEITO PARA EVITAR A RECORRÊNCIA"
                            />
                            <FormComp
                                title="WHY ( POR QUE SERÁ FEITO ? )"
                                type="textarea"
                                valor={formValues.why}
                                changeFunc={(e) => handleChange('why', e)}
                            />
                            <FormComp
                                title="WHERE ( ONDE SERÁ FEITO ? )"
                                type="textarea"
                                valor={formValues.where}
                                changeFunc={(e) => handleChange('where', e)}
                            />
                            <FormComp
                                title="WHEN ( QUANDO SERÁ FEITO ? )"
                                type="date"
                                valor={formValues.when}
                                changeFunc={(e) => handleChange('when', e)}
                            />
                            <FormComp
                                title="WHO ( POR QUEM SERÁ FEITO ? )"
                                type="select"
                                options={lideres}
                                valor={formValues.who}
                                changeFunc={(e) => handleChange('who', e)}
                            />
                            <FormComp
                                title="HOW ( COMO SERÁ FEITO ? )"
                                type="textarea"
                                valor={formValues.how}
                                changeFunc={(e) => handleChange('how', e)}
                            />
                            <FormComp
                                title="HOW MUCH ( QUANTO VAI CUSTAR ? )"
                                type="number"
                                valor={formValues.how_much}
                                changeFunc={(e) => handleChange('how_much', e)}
                            />
                            <FormComp
                                title="DATA REALIZADA"
                                type="date"
                                valor={formValues.data_realizada}
                                changeFunc={(e) => handleChange('data_realizada', e)}
                            />
                            <div className={ style.listaAnexos } >
                                <p className={ style.titleAnexos } >LISTA DE ANEXOS</p>
                                <div className={ style.tabelaAnexosCont } >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>ARQUIVO</th>
                                                <th>TAMANHO</th>
                                                <th>APAGAR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                anexos.length === 0 ? <tr><td colSpan={ 3 } >NENHUM ARQUIVO ANEXADO</td></tr> :
                                                anexos.map( obj => (
                                                    <tr key={ obj.id } >
                                                        <td>
                                                            <Link to={ obj.caminho } target='_blank' >
                                                                { obj.file_name }
                                                            </Link>
                                                        </td>
                                                        <td>{ obj.file_size }</td>
                                                        <td>*</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <FormComp
                                title="ANEXOS"
                                type="file"
                                multiple
                                changeFunc={ handleFileChange }
                            />
                            <FormComp
                                title="OBSERVAÇÕES"
                                type="textarea"
                                valor={formValues.observacoes}
                                changeFunc={(e) => handleChange('observacoes', e)}
                            />
                            <FormComp
                                title="EFICÁCIA DAS AÇÕES"
                                type="select"
                                options={["SIM"]}
                                valor={formValues.eficacia}
                                changeFunc={(e) => handleChange('eficacia', e)}
                            />
                            <FormComp
                                title="APROVADO POR"
                                type="select"
                                options={lideres}
                                valor={formValues.aprovado_por}
                                changeFunc={(e) => handleChange('aprovado_por', e)}
                            />
                            <FormComp
                                title="DATA DE ENCERRAMENTO"
                                type="date"
                                valor={formValues.data_encerramento}
                                changeFunc={(e) => handleChange('data_encerramento', e)}
                            />
                            <FormComp
                                type="submit"
                                valor="SALVAR"
                            />
                        </form>
                    </div>
            }
        </div>
    )

}

export default RacForm;