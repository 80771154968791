import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "./Auth/AuthProvider";
import PrivateRoute from "./Auth/ProtectedRoute";

import App from "./App";
import Register from "./Routes/Register";
import NewLogin from "./Routes/NewLogin";
import NewHome from "./Routes/NewHome";
import Colab from "./Routes/Colab";
import Treinamentos from "./Routes/Treinamentos";
import AuditoriaInterna from "./Routes/AuditoriaInterna";
import PlanejAuditInterna from "./Routes/PlanejAuditInterna";
import AuditoriaInternaId from "./Routes/AuditInternaId";
import AuditoriaCheck from "./Routes/AuditoriaCheck";
import RAC from "./Routes/RAC";
import Afastamentos from "./Routes/Afastamentos";
import Recisoes from "./Routes/Recisoes";
import ExamesOcupa from "./Routes/ExamesOcupa";
import FolhaPag from "./Routes/FolhaPag";
import Admissao from "./Routes/Admissao";
import NewReps from "./Routes/NewReps";
import FolgaCampo from "./Routes/FolgaCampo";
import UsersList from "./Routes/UsersList";
import InfoPanel from "./Routes/InfoPanel";
import GheFuncoes from "./Componentes/InfoPanel/GheFuncoes";
import ExamesGHE from "./Componentes/InfoPanel/ExamesGHE";
import TreinamentosGHE from "./Componentes/InfoPanel/TreinamentosGHE";
import CronogramTreinamentos from "./Routes/CronogramTreinamentos";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "/Login", element: <NewLogin /> },
      { path: "/Register", element: <Register /> },

      {
        element: <PrivateRoute />,
        children: [
          { path: "/", element: <NewHome /> },
          { path: "/Home", element: <NewHome /> },
          { path: "/Colaborador/:matricula", element: <Colab /> },

          {
            path: "/InfoPanel",
            element: <InfoPanel />,
            children: [
              { path: "ghe-funcoes", element: <GheFuncoes /> },
              { path: "exames-ghe", element: <ExamesGHE /> },
              { path: "treinamentos-ghe", element: <TreinamentosGHE /> }
            ]
          },

          { path: "/Treinamentos/", element: <Treinamentos /> },
          { path: "/CronogramTreinamentos/", element: <CronogramTreinamentos /> },

          { path: "/Treinamentos/form/:id?", element: <Treinamentos /> },
          { path: "/Treinamentos/new", element: <Treinamentos /> },
          { path: "/Treinamentos/view/:id", element: <Treinamentos /> },
          { path: "/Treinamentos/edit/:id", element: <Treinamentos /> },
          { path: "/TreinamentosByFunc", element: <></> },
          { path: "/Afastamentos", element: <Afastamentos /> },
          { path: "/Recisoes", element: <Recisoes /> },
          { path: "/ExamOcup", element: <ExamesOcupa /> },
          { path: "/ProcessoAdm", element: <Admissao /> },
          { path: "/Republicas", element: <NewReps /> },
          { path: "/FolgaCampo", element: <FolgaCampo /> },
          { path: "/UserList", element: <UsersList /> },
        ],
      },
      {
        element: <PrivateRoute requiredPermission={["admin", "can_access_folha_pag"]} />,
        children: [
          { path: "/FolhaPag", element: <FolhaPag /> },
        ]
      },
      {
        element: <PrivateRoute requiredPermission={["admin", "can_access_audit"]} />,
        children: [
          { path: "/AuditoriaInterna", element: <AuditoriaInterna /> },
          { path: "/AuditoriaInterna/Id/:id", element: <AuditoriaInternaId /> },
          { path: "/PlanejAuditoria/:id", element: <PlanejAuditInterna /> },
          { path: "/AuditoriaCheck/Id/:id", element: <AuditoriaCheck /> },
        ]
      },
      {
        element: <PrivateRoute requiredPermission={["admin", "can_access_rac"]} />,
        children: [
          { path: "/RAC/:id?", element: <RAC /> },
        ]
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);