import style from './FormContainer.module.css';

import { MdOutlineClose } from "react-icons/md";


const FormContainer = ({ children, closeFunc }) => {

    return(
        <div className={ style.container } >
            <div className={ style.header } >
                <div
                    className={ style.closeDiv }
                    onClick={ closeFunc }
                >
                    <MdOutlineClose />
                </div>
            </div>
            <div className={ style.body } >
                { children }
            </div>
        </div>
    )
}

export default FormContainer;