import style from './SmallLoading.module.css';

import { TbLoader2 } from "react-icons/tb";

const SmallLoading = () => {

    return(
        <div className={ style.container } >
            <div className={ style.loading }>
                <TbLoader2 className={ style.spinner } />
            </div>
            <div className={ style.textContainer } >
                <p>CARREGANDO...</p>
            </div>
        </div>
    )

}

export default SmallLoading;