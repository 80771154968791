import { NavLink } from 'react-router-dom';
import style from './SidePanel.module.css';

const SidePanel = () => {

    return (
        <div className={ style.sidePanel } >
            <img
                src='/Images/Logo.png'
                alt='logo-palmont'
                className={ style.imageLogo }    
            />

            <div className={ style.menuList } >
                <NavLink to="/InfoPanel/ghe-funcoes" >
                    {({ isActive }) => (
                        <div className={ isActive ? style.menuItemActive : style.menuItem } > <p> GHE x Funções </p></div>
                    )}
                </NavLink>
                <NavLink to="/InfoPanel/exames-ghe" >
                    {({ isActive }) => (
                        <div className={ isActive ? style.menuItemActive : style.menuItem } > <p> Exames x GHE </p></div>
                    )}
                </NavLink>
                <NavLink to="/InfoPanel/treinamentos-ghe" >
                    {({ isActive }) => (
                        <div className={ isActive ? style.menuItemActive : style.menuItem } > <p> Treinamentos x GHE </p></div>
                    )}
                </NavLink>
            </div>
        </div>
    )

}

export default SidePanel;