import { useState } from "react";
import style from './SaveAnexos.module.css';

import { MdCloudUpload } from "react-icons/md";
import { LuFilePlus2 } from "react-icons/lu";
import { RiCloseLargeLine } from "react-icons/ri";
import { VscFilePdf } from "react-icons/vsc";
import { IoTrashOutline } from "react-icons/io5";
import axios from "axios";

const SaveAnexos = ({ close, idSelected, updateTable }) => {
    const [file, setFile] = useState(null);
    const [ uploading, setUploading ] = useState( false );

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const removeFile = () => {

        setFile( null );

    }

    const handleCancel = () => {

        setFile(null);
        close();

    };

    const handleSave = async () => {

        if (!file) {
            alert("Nenhum arquivo selecionado!");
            return;
        }

        try{

            setUploading( true );

            const formData = new FormData();
            formData.append('id', idSelected);
            formData.append('file', file);

            await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/Treinamentos/UploadCertificado/', formData, { withCredentials: true });

            await updateTable();

            alert("Arquivo salvo com sucesso!");

            setFile( null );
            close();

        }catch( e ){

            console.log( e );

        }finally{

            setUploading( false );

        }

    };

    const formatFileSize = (size) => {
        return size < 1024
            ? `${size} B`
            : size < 1024 * 1024
            ? `${(size / 1024).toFixed(2)} KB`
            : `${(size / (1024 * 1024)).toFixed(2)} MB`;
    };

    return (
        <div className={style.container}>
            <div className={style.headerFiles}>
                <LuFilePlus2 className={style.iconHeader} />
                <p className={style.textHeader}>Anexar certificado</p>
                <div className={style.containerClose}>
                    <RiCloseLargeLine className={style.iconClose} onClick={ handleCancel } />
                </div>
            </div>

            {file ?
                <div className={style.fileContainer} >
                    <div className={ style.fileType } >
                        <VscFilePdf className={ style.fileTypeIcon } />
                    </div>
                    <div className={ style.fileContents } >
                        <p className={ style.fileName } >{ file.name }</p>
                        <p className={ style.fileSize } >{formatFileSize( file.size )}</p>
                    </div>
                    <div className={ style.fileRemov } >
                        <IoTrashOutline className={ style.fileRemovIcon } onClick={ removeFile } />
                    </div>
                </div>
                :
                <label className={style.fileContainerPending}>
                    <input
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        accept=".pdf"
                    />
                    <MdCloudUpload className={style.iconUpload} />
                    <p>Clique para fazer upload do certificado</p>
                </label>}

            <div className={style.buttonsContainer}>
                <button className={style.buttonCancel} onClick={handleCancel}>
                    CANCELAR
                </button>
                <button className={style.buttonSave} onClick={handleSave} disabled={ uploading } >
                    {
                        uploading ? "SALVANDO..." : "SALVAR"
                    }
                </button>
            </div>
        </div>
    );
};

export default SaveAnexos;