import style from './RepsBody.module.css';

import Select, { components } from 'react-select';
import { FiPlus } from "react-icons/fi";
import { MdModeEditOutline, MdRemoveRedEye, MdDelete } from "react-icons/md";
import { useCallback, useEffect, useState } from 'react';
import FilterContainer from '../Utils/FilterContainer';
import Tabela from '../Tabela';

const RepsBody = ({ dados, openRep, openView, filter, dadosFilter }) => {

    const [vagas, setVagas] = useState(0)
    const [alojados, setAlojados] = useState(0)

    const [obras, setObras] = useState([])
    const [cidades, setCidades] = useState([])

    useEffect(() => {

        setVagas(dados.reduce((acc, curr) => acc + parseInt(curr.vagas), 0))
        setAlojados(dados.reduce((acc, curr) => acc + curr.alojados, 0))

        setObras([...new Set(dadosFilter.map(obj => obj.centro_custo).sort())])
        setCidades([...new Set(dadosFilter.map(obj => obj.cidade).sort())])

    }, [dados, dadosFilter])

    const [repubicaVal, setRepublicaVal] = useState('');
    const [centroCustoVal, setCentroCustoVal] = useState([]);
    const [cidadeVal, setCidadeVal] = useState([]);
    const [vagasVal, setVagasVal] = useState([]);

    const handleFilter = useCallback(() => {

        filter(
            dadosFilter.filter((item) => {

                const repName = !repubicaVal || item.apelido_rep.includes(repubicaVal);
                const centroCusto = !centroCustoVal.length || centroCustoVal.some(obj => (item.centro_custo || '').includes(obj.value))
                const cidadesFilter = !cidadeVal.length || cidadeVal.some(obj => (item.cidade || '').includes(obj.value))

                const vagasFilter = !vagasVal.length ||
                    (vagasVal.some(obj => obj.value === 'COM VAGAS DISPONÍVEIS') && parseInt(item.vagas) - item.alojados > 0) ||
                    (vagasVal.some(obj => obj.value === 'SEM VAGAS DISPONÍVEIS') && parseInt(item.vagas) - item.alojados === 0);

                return repName && centroCusto && cidadesFilter && vagasFilter

            })
        )

    }, [repubicaVal, centroCustoVal, cidadeVal, vagasVal])

    useEffect(() => {

        handleFilter()

    }, [handleFilter]);

    return (
        <div className={style.bodyRep} >

            <div className={style.repHeader} >
                <div className={style.repFilters} >
                    <div className={style.repFilterContainer} >
                        <input
                            type='text'
                            placeholder='REPUBLICA...'
                            value={repubicaVal}
                            onChange={(e) => setRepublicaVal(e.target.value.toUpperCase())}
                        />
                    </div>
                    <div className={style.repFilterContainer} >
                        <FilterContainer
                            type="select"
                            placeholder="CENTRO CUSTO..."
                            options={obras}
                            optType="obj"
                            valor={centroCustoVal}
                            changeFunc={(value) => setCentroCustoVal(value)}
                        />
                    </div>
                    <div className={style.repFilterContainer} >
                        <FilterContainer
                            type="select"
                            placeholder="CIDADE..."
                            options={cidades}
                            optType="obj"
                            valor={cidadeVal}
                            changeFunc={(value) => setCidadeVal(value)}
                        />
                    </div>
                    <div className={style.repFilterContainer} >
                        <FilterContainer
                            type="select"
                            placeholder="DISPONIBILIDADE DE VAGAS"
                            options={
                                [
                                    { value: 'COM VAGAS DISPONÍVEIS', label: 'COM VAGAS DISPONÍVEIS' },
                                    { value: 'SEM VAGAS DISPONÍVEIS', label: 'SEM VAGAS DISPONÍVEIS' }
                                ]
                            }
                            valor={vagasVal}
                            changeFunc={(value) => setVagasVal(value)}
                        />
                    </div>
                </div>
                <div className={style.repStats} >

                    <p>TOTAL DE VAGAS: {vagas}</p>
                    <p>VAGAS DISPONÍVEIS: {vagas - alojados}</p>
                    <p>ALOJADOS: {alojados}</p>

                </div>
            </div>
            <Tabela>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>REPUBLICA</th>
                        <th>CENTRO CUSTO</th>
                        <th>LOGRADOURO</th>
                        <th>NÚMERO</th>
                        <th>COMPLEMENTO</th>
                        <th>BAIRRO</th>
                        <th>CIDADE</th>
                        <th>ESTADO</th>
                        <th>QUARTOS</th>
                        <th>VAGAS</th>
                    </tr>
                </thead>
                <tbody>
                    {dados.map(obj => (

                        <tr className={style.linhaTabela} key={obj.identifier} >
                            <td className={style.viewAlojados} >
                                <MdRemoveRedEye
                                    size={16}
                                    onClick={() => openView(obj.identifier)}
                                />
                            </td>
                            <td className={style.editAlojamentos} >
                                <MdModeEditOutline size={19} />
                            </td>
                            <td className={style.deleteAlojamento} >
                                <MdDelete size={17} />
                            </td>
                            <td>{obj.apelido_rep}</td>
                            <td>{obj.centro_custo}</td>
                            <td>{obj.logradouro}</td>
                            <td>{obj.numero}</td>
                            <td>{obj.complemento ? obj.complemento : '-'}</td>
                            <td>{obj.bairro}</td>
                            <td>{obj.cidade}</td>
                            <td>{obj.estado}</td>
                            <td>{obj.quartos}</td>
                            <td>{obj.alojados + "/" + obj.vagas}</td>
                        </tr>

                    ))}
                </tbody>
            </Tabela>
        </div>
    )
}

export default RepsBody;