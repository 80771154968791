import { useCallback, useEffect, useState } from 'react';
import { formatarData } from '../../../Funcoes/FormatarData';
import style from './AuditItens.module.css';

import classNames from 'classnames';
import Select, { components } from 'react-select';
import { getItensByNorma } from '../../../Funcoes/GetItensByNorma';
import Tabela from '../../Tabela';

const AuditIntens = ({ itens, itensToFilter, openEdit, handleFilter }) => {

    const [auditores, setAuditores] = useState([]);
    const [auditados, setAuditados] = useState([]);

    const [percentage, setPercentage] = useState(0.00)

    useEffect(() => {

        if (itens) {

            console.log(itens)

            const conformidades = itens.reduce((acc, item) => {

                return (item.conformidade === 'C' || item.conformidade === 'NA' || item.conformidade === 'OM') ? acc + 1 : acc

            }, 0)

            const total = itens.length
            setPercentage((conformidades / total) * 100.00)

        }

    }, [itens])

    useEffect(() => {

        const distinctAuditores = [...new Set(itensToFilter.map(obj => obj.auditor))].sort();
        const distinctAuditados = [...new Set(itensToFilter.map(obj => obj.auditado))].sort();

        setAuditores(distinctAuditores);
        setAuditados(distinctAuditados);

    }, [itensToFilter])

    const itensC = itens.filter(obj => obj.conformidade === 'C').length
    const itensNC = itens.filter(obj => obj.conformidade === 'NC').length
    const itensNA = itens.filter(obj => obj.conformidade === 'NA').length
    const itensOM = itens.filter(obj => obj.conformidade === 'OM').length

    const classeCircle = classNames(style.circlePercent, {

        [style.lowPercent]: percentage <= 50,
        [style.mediumPercent]: percentage > 50 && percentage <= 75,
        [style.highPercent]: percentage > 75

    })

    const ValueContainer = ({ children, ...props }) => {

        let [values, input] = children
        if (Array.isArray(values)) {

            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`

        }

        return (

            <components.ValueContainer {...props} >
                {values}
                {input}
            </components.ValueContainer>

        )

    }

    const [selectedNorma, setSelectedNorma] = useState([])

    const [itensNorma, setItensNorma] = useState([])
    const [selectedItens, setSelectedItens] = useState([])
    const [selectedAuditores, setSelectedAuditores] = useState([])
    const [selectedAuditados, setSelectedAuditados] = useState([])
    const [selectedSetores, setSelectedSetores] = useState([])
    const [selectedConf, setSelectedConf] = useState([])


    useEffect(() => {

        const normaValues = selectedNorma.map(obj => obj.value)

        const itensList = Array.from(
            new Set(
                normaValues.flatMap(norma => {
                    const itensRetornados = getItensByNorma(norma) || [];
                    return itensRetornados;
                })
            )
        ).sort((a, b) => a.localeCompare(b))

        setItensNorma(itensList)
        setSelectedItens([])

    }, [selectedNorma])

    useEffect(() => {

        handleFilter(selectedNorma, selectedItens, selectedAuditores, selectedAuditados, selectedSetores, selectedConf)

    }, [selectedNorma, selectedItens, selectedAuditores, selectedAuditados, selectedSetores, selectedConf])

    const setores = [
        "ADM",
        "ALMOXARIFADO/MATERIAIS",
        "ALTA DIREÇÃO",
        "ANDAIMES",
        "COMERCIAL",
        "CONTABILIDADE",
        "CONTROLE DE OBRAS",
        "ELÉTRICA",
        "ENGENHARIA E ORÇAMENTOS",
        "FABRICAÇÃO (PIPE SHOP)",
        "FINANCEIRO",
        "FISCAL",
        "IÇAMENTO E MOVIMENTAÇÃO DE CARGA",
        "INSTRUMENTAÇÃO",
        "ISOLAMENTO TÉRMICO",
        "JURÍDICO",
        "LOGÍSTICA",
        "MANUTENÇÃO MECÂNICA",
        "MECÂNICA",
        "PINTURA",
        "PLANEJAMENTO/GERENCIAMENTO DE OBRAS",
        "QUALIDADE",
        "RECURSOS HUMANOS",
        "SGI - SISTEMA DE GESTÃO INTEGRADO",
        "SSMA",
        "SUPRIMENTOS",
        "T.I.",
        "TUBULAÇÃO"
    ]

    const tabelaRef = useState(null);

    const [linhasTabela, setLinhasTabela] = useState(100);

    useEffect(() => {

        const element = tabelaRef.current;

        if (!element) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = element;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setLinhasTabela((prev) => prev + 100);
            }

        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <div className={style.container} >
            <div className={style.header} >
                <div className={style.filtersContainer} >
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="NORMA..."
                            options={[
                                { value: 'ISO 9001:2015', label: 'ISO 9001:2015' },
                                { value: 'ISO 14001:2015', label: 'ISO 14001:2015' },
                                { value: 'ISO 45001:2018', label: 'ISO 45001:2018' }
                            ]}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedNorma(selected) }}
                            value={selectedNorma}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="ITEM..."
                            options={itensNorma.map(obj => ({
                                value: obj, label: obj
                            }))}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            value={selectedItens}
                            onChange={(selected) => { setSelectedItens(selected) }}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="AUDITOR..."
                            options={auditores.map(obj => ({ value: obj, label: obj }))}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedAuditores(selected) }}
                            value={selectedAuditores}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="AUDITADO..."
                            options={auditados.map(obj => ({ value: obj, label: obj }))}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedAuditados(selected) }}
                            value={selectedAuditados}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="SETOR..."
                            options={setores.map(obj => ({ value: obj, label: obj }))}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedSetores(selected) }}
                            value={selectedSetores}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="CONFORMIDADE..."
                            options={[
                                { value: "C", label: "C" },
                                { value: "NC", label: "NC" },
                                { value: "NA", label: "NA" },
                                { value: "OM", label: "OM" }
                            ]}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedConf(selected) }}
                            value={selectedConf}
                        />
                    </div>
                </div>
                <div className={style.statsContainer} >
                    <div className={style.statsLeft} >
                        <div className={style.confContainer} >{`C:  ${itensC}`}</div>
                        <div className={style.confContainer} >{`NC: ${itensNC}`}</div>
                        <div className={style.confContainer} >{`NA: ${itensNA}`}</div>
                        <div className={style.confContainer} >{`OM: ${itensOM}`}</div>
                    </div>
                    <div className={style.statesSeparator} ></div>
                    <div className={style.statsRight} >
                        <div className={classeCircle} >
                            <p>
                                {parseFloat(percentage).toFixed(2) + "%"}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Tabela ref={ tabelaRef } >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>ITEM</th>
                        <th>NORMA</th>
                        <th>DATA</th>
                        <th>AUDITADO</th>
                        <th>AUDITOR</th>
                        <th>SETOR</th>
                        <th>CONFORMIDADE</th>
                        <th>ANEXOS</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        itens.slice(0, linhasTabela).map(obj => (
                            <tr
                                key={obj.id}
                                onClick={() => { openEdit(obj.id) }}
                            >
                                <td>{obj.id_count}</td>
                                <td className={style.normaColumn} >{obj.subitem}</td>
                                <td>{obj.norma}</td>
                                <td>{obj.data ? formatarData(obj.data, true) : '-'}</td>
                                <td>{obj.auditado}</td>
                                <td>{obj.auditor}</td>
                                <td>{obj.setor}</td>
                                <td>{obj.conformidade}</td>
                                <td>{obj.anexos}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Tabela>
        </div>
    )

}

export default AuditIntens;