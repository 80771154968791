import style from './RacBody.module.css';

import Select, { components } from 'react-select';
import { formatarData } from '../../../Funcoes/FormatarData'
import { useEffect, useRef, useState } from 'react';

import { BsFiletypePdf } from "react-icons/bs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import axios from 'axios';
import { saveAs } from 'file-saver';
import Tabela from '../../Tabela';

const RacBody = ({ racs, openEdit, racsToFilter, handleFilter }) => {

    const [obras, setObras] = useState([])
    const [contratos, setContratos] = useState([])
    const [fonte, setFonte] = useState([])
    const [tipo_nc, setTipoNc] = useState([])
    const [processos, setProcessos] = useState([])
    const [preenchidos, setPreenchidos] = useState([])

    const emAberto = racs.filter(item => item.status === "EM ABERTO").length
    const finalizado = racs.filter(item => item.status === "FINALIZADO").length
    const noPrazo = racs.filter(item => item.STATUS === "NO PRAZO").length
    const atrasado = racs.filter(item => item.STATUS === "ATRASADO").length

    useEffect(() => {

        const obrasDistinct = [...new Set(racsToFilter.map(obj => obj.centro_custo))].sort();
        const contratosDistinct = [...new Set(racsToFilter.map(obj => obj.contrato))].sort();
        const fontesDistinct = [...new Set(racsToFilter.map(obj => obj.fonte))].sort();
        const tipoNcDistinct = [...new Set(racsToFilter.map(obj => obj.tipo_nc))].sort();
        const processosDistinct = [...new Set(racsToFilter.map(obj => obj.setor))].sort();
        const preenchidosDistinct = [...new Set(racsToFilter.map(obj => obj.created_by))].sort();

        setObras(obrasDistinct)
        setContratos(contratosDistinct)
        setFonte(fontesDistinct)
        setTipoNc(tipoNcDistinct)
        setProcessos(processosDistinct)
        setPreenchidos(preenchidosDistinct)

    }, [racsToFilter])

    const [selectedObras, setSelectedObras] = useState([])
    const [selectedContratos, setSelectedContratos] = useState([])
    const [selectedFontes, setSelectedFontes] = useState([])
    const [selectedTipoNc, setSelectedTipoNc] = useState([])
    const [selectedProcessos, setSelectedProcessos] = useState([])
    const [selectedStatus, setSelectedStatus] = useState([])
    const [selectedSituacao, setSelectedSituacao] = useState([])
    const [selectedPreenchidos, setSelectedPreenchidos] = useState([])

    useEffect(() => {

        handleFilter(selectedObras, selectedContratos, selectedFontes, selectedTipoNc, selectedProcessos, selectedStatus, selectedSituacao, selectedPreenchidos)

    }, [selectedObras, selectedContratos, selectedFontes, selectedTipoNc, selectedProcessos, selectedStatus, selectedSituacao, selectedPreenchidos, handleFilter])

    const ValueContainer = ({ children, ...props }) => {

        let [values, input] = children
        if (Array.isArray(values)) {

            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`

        }

        return (

            <components.ValueContainer {...props} >
                {values}
                {input}
            </components.ValueContainer>

        )

    }

    const [clickedBtns, setClickedBtns] = useState({})

    const downloadRelat = async (e, relatID) => {

        e.stopPropagation();

        setClickedBtns((prev) => ({
            ...prev,
            [relatID]: true
        }))

        try {

            const { data } = await axios({
                url: "https://sistemaintegrado.palmont.com.br/Painel/API/RAC/DownloadRelat/index.php?id=" + relatID,
                method: "GET",
                responseType: "blob"
            })

            const blob = new Blob([data], { type: "aplication/pdf" })
            saveAs(blob, "arquivo.pdf")

        } catch (error) {

            console.log(error)

        } finally {

            setClickedBtns((prev) => ({
                ...prev,
                [relatID]: false
            }))

        }

    }

    const tabelaRef = useRef(null)

    const [linhasTabela, setLinhasTabela] = useState(100)

    useEffect(() => {

        const element = tabelaRef.current;

        if (!element) return;

        const handleScroll = () => {

            const { scrollTop, scrollHeight, clientHeight } = element;

            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setLinhasTabela((prev) => prev + 100);
            }

        };

        element.addEventListener("scroll", handleScroll);

        return () => {
            element.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <div className={style.body} >
            <div className={style.container1} >
                <div className={style.filtersContainer} >
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="CENTRO CUSTO..."
                            options={
                                obras.map(obj => ({ value: obj, label: obj }))
                            }
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedObras(selected) }}
                            value={selectedObras}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="CONTRATO..."
                            options={
                                contratos.map(obj => ({ value: obj, label: obj }))
                            }
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedContratos(selected) }}
                            value={selectedContratos}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="FONTE..."
                            options={
                                fonte.map(obj => ({ value: obj, label: obj }))
                            }
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedFontes(selected) }}
                            value={selectedFontes}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="TIPO NC..."
                            options={
                                tipo_nc.map(obj => ({ value: obj, label: obj }))
                            }
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedTipoNc(selected) }}
                            value={selectedTipoNc}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="PROCESSO..."
                            options={
                                processos.map(obj => ({ value: obj, label: obj }))
                            }
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedProcessos(selected) }}
                            value={selectedProcessos}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="STATUS..."
                            options={[
                                { label: 'FINALIZADO', value: 'FINALIZADO' },
                                { label: 'EM ABERTO', value: 'EM ABERTO' }
                            ]}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedStatus(selected) }}
                            value={selectedStatus}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="SITUAÇÃO..."
                            options={[
                                { label: 'ATRASADO', value: 'ATRASADO' },
                                { label: 'NO PRAZO', value: 'NO PRAZO' }
                            ]}
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedSituacao(selected) }}
                            value={selectedSituacao}
                        />
                    </div>
                    <div className={style.filterBox} >
                        <Select
                            className={style.customSelect}
                            placeholder="PREENCHIDO POR..."
                            options={
                                preenchidos.map(obj => ({ value: obj, label: obj }))
                            }
                            isClearable
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ ValueContainer }}
                            onChange={(selected) => { setSelectedPreenchidos(selected) }}
                            value={selectedPreenchidos}
                        />
                    </div>
                </div>
                <div className={style.statsContainer} >
                    <div className={style.statsLeft} >
                        <p>EM ABERTO: {emAberto}</p>
                        <p>FINALIZADO: {finalizado}</p>
                    </div>
                    <div className={style.statsRight} >
                        <p>NO PRAZO: {noPrazo}</p>
                        <p>ATRASADO: {atrasado}</p>
                    </div>
                </div>
            </div>
            <Tabela ref={ tabelaRef } >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>ID RELACIONAL</th>
                        <th>FONTE</th>
                        <th>CENTRO CUSTO</th>
                        <th>CONTRATO</th>
                        <th>TIPO</th>
                        <th>PROCESSO</th>
                        <th>STATUS</th>
                        <th>PRAZO</th>
                        <th>ENCERRAMENTO</th>
                        <th>SITUAÇÃO</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        racs.slice( 0, linhasTabela ).map((rac, index) => {
                            return (
                                <tr
                                    key={index}
                                    onClick={() => openEdit(rac.id)}
                                >
                                    <td>{rac.id}</td>
                                    <td>
                                        {
                                            rac.fonte === 'AUDITORIA INTERNA' ? rac.id_auditoria + ' - ' + rac.id_item : "-"
                                        }
                                    </td>
                                    <td>{rac.fonte}</td>
                                    <td>{rac.centro_custo}</td>
                                    <td>{rac.contrato}</td>
                                    <td>{rac.tipo_nc}</td>
                                    <td>{rac.setor}</td>
                                    <td>{rac.status}</td>
                                    <td>{rac.when ? formatarData(rac.when) : '-'}</td>
                                    <td>{rac.encerramento ? formatarData(rac.encerramento) : '-'}</td>
                                    <td>{rac.STATUS ? rac.STATUS : '-'}</td>
                                    <td>
                                        {
                                            clickedBtns[rac.id] ?
                                                <button className={style.btnPdf} >
                                                    <AiOutlineLoading3Quarters className={style.iconLoad} />
                                                </button>
                                                :
                                                <button className={style.btnPdf} onClick={(e) => downloadRelat(e, rac.id)} >
                                                    <BsFiletypePdf className={style.iconPdf} />
                                                </button>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </Tabela>
        </div>
    )

}

export default RacBody;