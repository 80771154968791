import style from './TreinamentosGHE.module.css';


import SmallLoading from "../../Utils/SmallLoading";
import Overlay from '../../Overlay';

import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from "react";
import FormContainer from '../../Utils/FormContainer';
import FormComp from '../../FormComp';
import FilterContainer from '../../Utils/FilterContainer';

import { AuthContext } from '../../../Auth/AuthProvider';

const TreinamentosGHE = () => {

    const { user } = useContext( AuthContext )

    console.log( user );

    const [ loading, setLoading ] = useState( false )
    const [ loadingForm, setLoadingForm ] = useState( true )
    const [ savingInfo, setSavingInfo ] = useState( false );

    const [ overlayForm, setOverlayForm ] = useState( false )

    const [ responseValues, setResponseValues ] = useState([])
    const [ responseValuesFilter, setResponseValuesFilter ] = useState([])

    const [ obras, setObras ] = useState([])
    const [ ghes, setGhes ] = useState([])
    const [ treinamentos, setTreinamentos ] = useState([])

    const [ obrasVal, setObrasVal ] = useState([])
    const [ gheVal, setGheVal ] = useState([])
    const [ treinamentoVal, setTreinamentoVal ] = useState([])

    const getValues = async () => {

        setLoading( true )

        try{

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/InfoPanel/GHE_Treinamentos/')

            console.log( data )
            setResponseValues( data )
            setResponseValuesFilter( data )

            setObras([ ... new Set( data.map( obj => obj.CENTRO_CUSTO ).sort())])
            setGhes([... new Set( data.map( obj => obj.GHE ).sort())])
            setTreinamentos([... new Set( data.map( obj => obj.TREINAMENTO ).sort())])

        }catch( e ){

            console.log( e )

        }finally{

            setLoading( false );

        }

    }

    const handleFilter = useCallback(() => {

        setResponseValuesFilter(
            responseValues.filter(( item ) => {

                const obraFilter = !obrasVal.length || obrasVal.some( obj => ( item.CENTRO_CUSTO || '' ).includes( obj.value ))
                const gheFilter =!gheVal.length || gheVal.some( obj => ( item.GHE || '' ).includes( obj.value ))
                const treinamentoFilter =!treinamentoVal.length || treinamentoVal.some( obj => ( item.TREINAMENTO || '' ).includes( obj.value ))

                return obraFilter && gheFilter && treinamentoFilter

            })
        )

    }, [ obrasVal, gheVal, treinamentoVal ])

    useEffect(() => {

        handleFilter();

    }, [ handleFilter ])

    const [ gheOpt, setGheOpt ] = useState([])
    const [ treinamentoOpt, setTreinamentoOpt ] = useState([])
    const [ obrasOpt, setObrasOpt ] = useState([])

    const getOptionsValues = async () => {

        setLoadingForm( true );

        try{

            const { data } = await axios.get("https://sistemaintegrado.palmont.com.br/Painel/API/InfoPanel/GetValues/")
            console.log( data )

            setGheOpt( data.GHE )
            setTreinamentoOpt( data.TREINAMENTOS )
            setObrasOpt( data.OBRAS )

        }catch( e ){

            console.log( e )

        }finally{

            setLoadingForm( false );

        }

    }

    const [ formValues, setFormValues ] = useState({
        centro_custo: '',
        ghe: '',
        treinamento: ''
    })

    const handleChange = ( name, value ) => {

        setFormValues( {
           ...formValues,
            [name]: value
        })

    }

    useEffect(() => {

        getValues();
        getOptionsValues();

    }, [])

    const closeForm = () => {

        setOverlayForm( false );
        setFormValues( 
            {
                centro_custo: '',
                ghe: '',
                treinamento: ''
            }
        )

    }

    const submitForm = async ( e ) => {

        e.preventDefault();
        setSavingInfo( true );
        
        try{

            const formData = new FormData();
            Object.entries(formValues).forEach(([key, value]) => {
                formData.append(key, value);
            });
    
            await axios.post(
                'https://sistemaintegrado.palmont.com.br/Painel/API/InfoPanel/SaveGheTreinamento/',
                formData,
                { withCredentials: true }
            );

            await Promise.all([getValues(), getOptionsValues()]);

            alert('Informações salvas com sucesso!');
            setFormValues( prev => {
                return {
                   ...prev,
                    treinamento: ''
                }
            })

        }catch( error ){

            console.log( error );
            alert( error.response.data.error )

        }finally{

            setSavingInfo( false );

        }


    }

    return(
        <>
            {
                loading
                ? <SmallLoading />
                : <>
                    <div className={ style.headTitle } >
                        <p className={ style.title } >TREINAMENTOS x GHE</p>
                        {
                            user?.PERMISSIONS?.includes('can_add_new_treina_ghe') && (
                                <button className={ style.btnNovo } onClick={ () => setOverlayForm( true ) } >NOVO</button>
                            )
                        }
                    </div>
                    <div className={ style.containerFilters } >
                        <FilterContainer
                            type="select"
                            placeholder="CENTRO CUSTO..."
                            options={ obras }
                            optType="obj"
                            valor={ obrasVal }
                            changeFunc={( selected ) => setObrasVal( selected )}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="GHE..."
                            options={ ghes }
                            optType="obj"
                            valor={ gheVal }
                            changeFunc={( selected ) => setGheVal( selected )}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="TREINAMENTO..."
                            options={ treinamentos }
                            optType="obj"
                            valor={ treinamentoVal }
                            changeFunc={( selected ) => setTreinamentoVal( selected )}
                        />
                    </div>
                    <div className={ style.containerTable } >
                        <table>
                            <thead>
                                <tr>
                                    <th>CENTRO CUSTO</th>
                                    <th>GHE</th>
                                    <th>TREINAMENTO</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    responseValuesFilter.slice( 0, 100 ).map( obj => (
                                        <tr key={ obj.ID } >
                                            <td>{ obj.CENTRO_CUSTO }</td>
                                            <td>{ obj.GHE }</td>
                                            <td>{ obj.TREINAMENTO }</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            }
            {
                <Overlay state={ overlayForm } >
                    <FormContainer closeFunc={ closeForm } >
                        <form onSubmit={ submitForm } >
                            <FormComp
                                title="CENTRO CUSTO"
                                type="select"
                                options={ obrasOpt.map( obj => obj.OBRA ) }
                                valor={ formValues.centro_custo }
                                changeFunc={( e ) => handleChange( 'centro_custo', e.target.value )}
                                required
                            />
                            <FormComp
                                title="GHE"
                                type="select"
                                options={
                                    gheOpt.map( obj => (
                                        <option key={ obj.IDENTIFIER } value={ obj.IDENTIFIER } >{ obj.GHE }</option>
                                    ))
                                }
                                mapped
                                valor={ formValues.ghe }
                                changeFunc={( e ) => handleChange( 'ghe', e.target.value )}
                                required
                            />
                            <FormComp
                                title="TREINAMENTO"
                                type="select"
                                options={ 
                                    treinamentoOpt.map( obj => (
                                        <option key={ obj.IDENTIFIER } value={ obj.IDENTIFIER } >{ obj.TREINAMENTO }</option>
                                    ))
                                }
                                mapped
                                valor={ formValues.treinamento }
                                changeFunc={( e ) => handleChange( 'treinamento', e.target.value )}
                                required
                            />
                            <FormComp
                                type="submit"
                                valor={ savingInfo ? "SALVANDO..." : "SALVAR" }
                                disabled={ savingInfo }
                            />
                        </form>
                    </FormContainer>
                </Overlay>
            }
        </>
    )

}

export default TreinamentosGHE;