import style from './FormComp.module.css';

import Select, { components } from 'react-select';

const FormComp = ({ title, titleHover, type, options, mapped, valor, changeFunc, readOnly, disabled, maxLength, placeholder, required, name, multiple, obj }) => {

    const ValueContainer = ({ children, ...props }) => {

        let [values, input] = children
        if (Array.isArray(values)) {

            const plural = values.length === 1 ? "" : "S";
            values = `${values.length} SELECIONADO${plural}`

        }

        return (

            <components.ValueContainer {...props} >
                {values}
                {input}
            </components.ValueContainer>

        )

    }

    return (

        <div className={style.formChild} >

            <p title={titleHover} >{title}</p>

            {type === 'select' ? (

                <select
                    style={disabled ? { backgroundColor: "white", color: "black", opacity: 1 } : {}}
                    value={valor}
                    onChange={changeFunc}
                    disabled={disabled}
                    readOnly={readOnly}
                    required={required}
                    name={name}
                    multiple={multiple}
                >
                    <option value="">SELECIONE...</option>

                    { mapped ? (
                        options
                    ) : obj ? (
                        options.map((opt) => (
                            <option value={opt.value} key={opt.value}>
                                {opt.label}
                            </option>
                        ))
                    ) : (
                        options.map((opt, index) => (
                            <option value={opt} key={index}>
                                {opt}
                            </option>
                        ))
                    )}
                </select>

            ) : type === 'textarea' ? (
                <textarea
                    value={valor}
                    onChange={changeFunc}
                    disabled={disabled}
                    required={required}
                    name={name}
                />
            ) : type === 'multiselect' ? (
                <Select
                    placeholder={placeholder}
                    value={valor}
                    options={options}
                    isClearable
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ ValueContainer }}
                    onChange={changeFunc}
                />
            ) : (
                <input
                    type={type}
                    value={valor}
                    onChange={changeFunc}
                    readOnly={readOnly}
                    disabled={disabled}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    required={required}
                    name={name}
                    multiple={multiple}
                />
            )
            }

        </div>

    )

}

export default FormComp;