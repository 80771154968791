import SmallLoading from '../Utils/SmallLoading';
import style from './Tabela.module.css';

import { forwardRef } from 'react';

const Tabela = forwardRef(({ children, loading }, ref) => {

    return (
        <div ref={ref} className={style.container} >
            {
                loading ? (
                    <SmallLoading />
                ) : (
                        <table>
                            {children}
                        </table>
                    )
            }
        </div>
    )
})

export default Tabela;