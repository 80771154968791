import { Outlet } from 'react-router-dom';
import style from './MainContent.module.css';

const MainContent = () => {

    return(
        <div className={ style.container } >
            <Outlet />
        </div>
    )

}

export default MainContent;