import { useEffect, useState, useCallback, useRef } from "react";
import NavBar from "../Componentes/NavBar";

import { useParams } from "react-router-dom";
import axios from "axios";
import PlanejamentoBody from "../Componentes/AuditInterna/PlanejamentoBody";
import Overlay from "../Componentes/Overlay";
import SideMenu from "../Componentes/SideMenu";
import SideMenuBtn from "../Componentes/SideMenuBtn";
import FormPlanej from "../Componentes/AuditInterna/FormPlanej";
import { ToastContainer } from 'react-toastify';

const PlanejAuditInterna = () => {

    const { id } = useParams();
    const [ auditoria, setAuditoria ] = useState({});
    const [ selectedItem, setSelectedItem ] = useState( null );

    const [ overlay, setOverlay ] = useState( false );
    const [ overlayForm, setOverlayForm ] = useState( false );

    const fetchPlanejAudit = useCallback( async () => {

        try {

            const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/AuditInterna/GetPlanejAudit/', { id });
            setAuditoria(data);

        } catch (error) {

            console.error(error);

        }

    }, []);
    
    useEffect(() => {

        fetchPlanejAudit();

    }, [ id ]);

    const openOverlay = () => {

        setOverlay( true )

    }

    const closeOverlay = () => {

        setOverlay( false )

    }

    const openForm = ( id ) => {

        setOverlay( false )
        setOverlayForm( true )

        if( id ) setSelectedItem( id );

    }

    const closeFormulario = () => {

        setOverlayForm( false )
        setSelectedItem( null )

    }

    return(
        <>
            <NavBar
                title={ 'PLANEJAMENTO AUDITORIA - ' + auditoria.contrato + ' | ' + auditoria.ano }
                changeState={ openOverlay }
                prevDest="AuditoriaInterna"
            />
            <PlanejamentoBody
                planejamento={ auditoria.planejamento }
                editItem={ openForm }
            />
            {
                overlay && (
                    <Overlay
                        state={ true }
                        changeState={ closeOverlay }
                    >
                        <SideMenu>
                            <SideMenuBtn
                                button="PLANEJAR SETOR"
                                clickFunc={ () => { openForm( null ) } }
                            />
                        </SideMenu>
                    </Overlay>
                )
            }
            {
                overlayForm && (
                    <Overlay
                        state={ true }
                    >
                        <FormPlanej
                            closeForm={ closeFormulario }
                            updatePlanej={ fetchPlanejAudit }
                            selectedItem={ selectedItem }
                        />
                    </Overlay>
                )
            }
            <ToastContainer
                style={{
                    zIndex: 99999
                }}
                autoClose={ 3000 }
                position="top-center"
                theme="dark"
            />
        </>
    )

}

export default PlanejAuditInterna;