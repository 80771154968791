import style from './CronogramTreinamentos.module.css';

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import GenericBody from "../Componentes/GenericBody";
import GenericHeader from "../Componentes/GenericHeader";
import NavBar from "../Componentes/NavBar";
import Tabela from "../Componentes/Tabela";
import axios from "axios";
import { AuthContext } from '../Auth/AuthProvider';

import { GoTrash } from "react-icons/go";
import { BiLoaderAlt } from "react-icons/bi";
import { IoMdCloseCircle } from "react-icons/io";

import Filters from '../Componentes/Utils/Filters';
import FilterContainer from '../Componentes/Utils/FilterContainer';
import Stats from '../Componentes/Utils/Stats';
import Overlay from '../Componentes/Overlay';
import SideMenu from '../Componentes/SideMenu';
import SideMenuBtn from '../Componentes/SideMenuBtn';
import FormContainer from "../Componentes/Utils/FormContainer";
import FormProgram from '../Componentes/Treinamentos/FormProgram';
import FormComp from '../Componentes/FormComp';

const CronogramTreinamentos = () => {

    const { user } = useContext(AuthContext);
    const permissions = user.PERMISSIONS

    const [loading, setLoading] = useState(false);
    const [deletingIds, setDeletingIds] = useState([]);

    const [sidebarOverlay, setSidebarOverlay] = useState(false);
    const [programOverlay, setProgramOverlay] = useState(false);
    const [createOverlay, setCreateOverlay] = useState(false);

    const [treinamentos, setTreinamentos] = useState([]);
    const [treinamentosFilter, setTreinamentosFilter] = useState([]);

    const fetchTreinamentos = async () => {

        setLoading(true);

        try {

            const { data } = await axios.get('http://localhost/Painel/API/Treinamentos/GetCronograma/')

            console.log(data);

            setTreinamentos(data);
            setTreinamentosFilter(data);

        } catch (e) {

            console.error(e)

        } finally {

            setLoading(false);

        }

    }

    useEffect(() => {

        fetchTreinamentos();

    }, [])

    const formatDateMonthYear = (dateString) => {
        if (!dateString || !dateString.includes('-')) return 'Data inválida';

        const [year, month] = dateString.split('-');
        const date = new Date(year, Number(month) - 1, 1); // Ajuste para o índice correto do mês

        let formattedDate = date.toLocaleDateString('pt-BR', { month: 'short', year: 'numeric' })
            .replace(' de ', '/')
            .replace('.', '');

        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    };

    const treinamentosName = useMemo(() => [...new Set(treinamentos.map((obj) => obj.treinamento))].sort(), [treinamentos])
    const obras = useMemo(() => [...new Set(treinamentos.map((obj) => obj.centro_custo))].sort(), [treinamentos])
    const contratos = useMemo(() => [...new Set(treinamentos.map((obj) => obj.contrato))].sort(), [treinamentos])

    const [treinamentosSel, setTreinamentosSel] = useState([])
    const [centroCustoSel, setCentroCustoSel] = useState([])
    const [contratoSel, setContratoSel] = useState([])
    const [statusSel, setStatusSel] = useState([])
    const [mesIni, setMesIni] = useState('')
    const [mesFim, setMesFim] = useState('')

    const handleChenge = (type, value) => {

        switch (type) {

            case 'treinamento':
                setTreinamentosSel(value)
                break;

            case 'centro_custo':
                setCentroCustoSel(value)
                break;

            case 'contrato':
                setContratoSel(value)
                break;

            case 'status':
                setStatusSel(value)
                break;

            case 'mes_ini':
                setMesIni(value)
                break;

            case 'mes_fim':
                setMesFim(value)
                break;

            default:
                break;

        }

    }

    const handleFilter = useCallback(() => {

        setTreinamentosFilter(
            treinamentos.filter((item) => {

                const trainingFilter = !treinamentosSel.length || treinamentosSel.some(obj => (item.treinamento || '').includes(obj.value));
                const obraFilter = !centroCustoSel.length || centroCustoSel.some(obj => (item.centro_custo || '').includes(obj.value));
                const contratoFilter = !contratoSel.length || contratoSel.some(obj => (item.contrato || '').includes(obj.value));
                const statusFilter = !statusSel.length || statusSel.some(obj => (item.status || '').includes(obj.value));

                const mesAnoItem = item.mes_ano ? new Date(item.mes_ano + '-01') : null;

                const mesInicio = mesIni ? new Date(mesIni + '-01') : null;
                const mesTermin = mesFim ? new Date(mesFim + '-01') : null;

                const filterInicio = !mesInicio || (mesAnoItem >= mesInicio)
                const filterTermino = !mesTermin || (mesAnoItem <= mesTermin)

                return trainingFilter && obraFilter && contratoFilter && statusFilter && filterInicio && filterTermino;

            })
        )

    }, [treinamentos, treinamentosSel, centroCustoSel, contratoSel, statusSel, mesIni, mesFim])

    useEffect(() => {

        handleFilter();

    }, [handleFilter])

    const openSidebar = () => {

        setSidebarOverlay(true);

    }

    const closeSidebar = () => {

        setSidebarOverlay(false);

    }

    const openProgram = () => {

        setProgramOverlay(true);

    }

    const closeProgram = () => {

        setProgramOverlay(false);

    }

    const handleDelete = async (id) => {

        if (!permissions.includes('can_manage_treina_schedule')) {

            return;

        }

        const item = treinamentos.find(obj => obj.id === id);
        if (item.status === 'REALIZADO') {

            alert('Não é possível apagar um treinamento que já foi realizado!');
            return;

        }

        setDeletingIds(
            (prev) => [...prev, id]
        );

        try {

            await axios.post('http://localhost/Painel/API/Treinamentos/DeleteCronogram/', {
                id: id
            })

            const newValues = treinamentos.map((obj) =>
                obj.id === id ? { ...obj, status: 'CANCELADO' } : obj
            );

            setTreinamentos(newValues);
            setTreinamentosFilter(newValues);

        } catch (e) {

            console.error(e);

        } finally {

            setDeletingIds(
                (prev) => prev.filter((item) => item !== id)
            );

        }

    }

    const openCreate = () => {

        setCreateOverlay( true );

    }

    const closeCreate = () => {

        setCreateOverlay( false );

    }

    return (
        <>
            <NavBar title='CRONOGRAMA DE TREINAMENTOS' setSideBar={openSidebar} />
            <GenericBody>
                <GenericHeader>
                    <Filters columns={3} >
                        <FilterContainer
                            type="select"
                            placeholder="TREINAMENTO..."
                            options={treinamentosName}
                            optType="obj"
                            changeFunc={(value) => handleChenge('treinamento', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="CENTRO CUSTO..."
                            options={obras}
                            optType="obj"
                            valor={centroCustoSel}
                            changeFunc={(value) => handleChenge('centro_custo', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="CONTRATO..."
                            options={contratos}
                            optType="obj"
                            valor={contratoSel}
                            changeFunc={(value) => handleChenge('contrato', value)}
                        />
                        <FilterContainer
                            type="select"
                            placeholder="STATUS..."
                            options={[
                                { label: 'REALIZADO', value: 'REALIZADO' },
                                { label: 'PENDENTE', value: 'PENDENTE' }
                            ]}
                            valor={statusSel}
                            changeFunc={(value) => handleChenge('status', value)}
                        />
                        <FilterContainer
                            type="month"
                            valor={mesIni}
                            changeFunc={(value) => handleChenge('mes_ini', value)}
                        />
                        <FilterContainer
                            type="month"
                            valor={mesFim}
                            changeFunc={(value) => handleChenge('mes_fim', value)}
                        />
                    </Filters>
                    <Stats>

                    </Stats>
                </GenericHeader>
                <Tabela
                    loading={loading}
                >
                    <thead>
                        <tr>
                            <th>TREINAMENTO</th>
                            <th>CENTRO CUSTO</th>
                            <th>CONTRATO</th>
                            <th>PREVISÃO</th>
                            <th>STATUS</th>
                            <th>COLABORADORES</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            treinamentosFilter.map((obj) => (
                                <tr key={obj.id} >
                                    <td>{obj.treinamento}</td>
                                    <td>{obj.centro_custo}</td>
                                    <td>{obj.contrato}</td>
                                    <td>{formatDateMonthYear(obj.mes_ano)}</td>
                                    <td>
                                        <div className={style.statusContainer} >
                                            <div className={
                                                obj.status === 'REALIZADO' ? style.divRealizado :
                                                    obj.status === 'CANCELADO' ? style.divCancelado :
                                                        style.divPendente
                                            }>
                                                {obj.status}
                                            </div>
                                        </div>
                                    </td>
                                    <td>{obj.status === 'REALIZADO' ? obj.quantidade : '-'}</td>
                                    <td>
                                        <div className={style.iconDiv} >
                                            <div className={style.iconDiv}>
                                                { permissions.includes('can_manage_treina_schedule') &&
                                                    ( deletingIds.includes(obj.id) ? (
                                                        <BiLoaderAlt className={style.loadIcon} />
                                                    ) : (
                                                        <GoTrash className={style.delIcon} onClick={() => handleDelete(obj.id)} />
                                                    ))
                                                }
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Tabela>
            </GenericBody>
            {
                sidebarOverlay &&
                <Overlay state={true} changeContent={closeSidebar} >
                    <SideMenu>
                        <SideMenuBtn
                            button="PROGRAMAR TREINAMENTO"
                            clickFunc={() => {

                                closeSidebar();
                                openProgram();

                            }}
                        />
                        <SideMenuBtn
                            button="CADASTRAR TREINAMENTO"
                            clickFunc={() => {
                                closeSidebar();
                                openCreate();
                            }}
                        />
                    </SideMenu>
                </Overlay>
            }
            {
                programOverlay &&
                <Overlay state={true} >
                    <FormContainer closeFunc={closeProgram} >
                        {
                            permissions.includes('can_manage_treina_schedule') ? (
                                <FormProgram closeFunc={closeProgram} updateDados={fetchTreinamentos} />
                            ) : ( 
                                <div className={ style.deniedPermission } >
                                    <IoMdCloseCircle size={ 45 } />
                                    <p>Você não possui permissão para programar treinamentos.</p>
                                </div>
                            )
                        }
                    </FormContainer>
                </Overlay>
            }
            {
                createOverlay &&
                <Overlay state={ true } >
                    <FormContainer closeFunc={ closeCreate } >
                        <form>
                            <FormComp
                                title="TREINAMENTO"
                                type="text"
                                placeholder="NOME DO TREINAMENTO..."
                            />
                            <FormComp
                                type="submit"
                                valor="SALVAR"
                            />
                        </form>
                    </FormContainer>
                </Overlay>
            }
        </>
    )
}

export default CronogramTreinamentos;