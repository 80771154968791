import LoadingScreen from "../Componentes/LoadingScreen";
import NavBar from "../Componentes/NavBar";

import axios from "axios";
import { useState, useEffect, useCallback, useContext } from "react";
import { AuthContext } from "../Auth/AuthProvider";

import FolhaPagBody from "../Componentes/FolhaPagBody";
import SideBarPagamentos from "../Componentes/SideBarPagamentos";
import OverlayPagamento from "../Componentes/OverlayPagamento";

const FolhaPag = () => {

    const { user, loading } = useContext( AuthContext )

    const [formValues, setFormValues] = useState({

        obra: null,
        motivo: null,
        matricula: '',
        valor: "0.00",
        data: null,
        user: null,
        observacoes: null

    })

    const motivos = [

        'ADC PF',
        'DESCONTO DIVERSOS',
        'DIARIA DE VIAGEM',
        'FOLGA DE DOMINGO',
        'PLR',
        'REEMBOLSO DIVERSOS',
        'REEMBOLSO DE PASSAGENS',
        'VA OBRA / CARTÃO ALELO',
        'VALE TRANSPORTE'

    ]

    const [folhaPag, setFolhaPag] = useState([]);
    const [filterFolhaPag, setFilterFolhaPag] = useState([]);

    const [overlayState, setOverlayState] = useState(false)
    const [overlayPag, setOverlayPag] = useState(false)

    const handleFilter = useCallback((filterMat, filterObra, filterMotivo, filterDataInicio, filterDataFinal) => {

        const filtered = folhaPag.filter(item => {

            const itemDate = new Date(item.data);

            const matricula_filter = !filterMat || item.matricula.includes(filterMat);
            const obra_filter = !filterObra || item.centro_custo === filterObra;
            const motivo_filter = !filterMotivo || item.motivo === filterMotivo;

            const start_date = !filterDataInicio || itemDate >= new Date(filterDataInicio);
            const end_date = !filterDataFinal || itemDate <= new Date(filterDataFinal);

            return matricula_filter && obra_filter && motivo_filter && start_date && end_date;

        })

        setFilterFolhaPag(filtered);

    }, [folhaPag])

    const handleDelete = (id) => {

        setFolhaPag(folhaPag.filter(item => item.temp_id !== id));
        setFilterFolhaPag(filterFolhaPag.filter(item => item.temp_id !== id));

    }

    useEffect(() => {

        if ( user ) {

            const fetchData = async () => {

                const id_user = user.ID_USER

                try {

                    const { data } = await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/GetFolhaPag/', {
                        user_id: id_user
                    });

                    setFolhaPag(data);
                    setFilterFolhaPag(data);

                } catch (error) {

                    console.log(error);

                }

            }

            fetchData();

        }

    }, [ user ]);

    return (
        <>

            <NavBar
                title="FOLHA DE PAGAMENTO"
                state={overlayState}
                changeState={setOverlayState}
            />

            <FolhaPagBody
                dados={filterFolhaPag}
                filter={handleFilter}
                usuario={user.USER}
                deleteTableRow={handleDelete}
            />

            <SideBarPagamentos
                overlayState={overlayState}
                changeOverlayState={setOverlayState}
                changeOverlayPag={setOverlayPag}
                dados={filterFolhaPag}
            />

            <OverlayPagamento
                overlayState={overlayState}
                changeOverlayState={setOverlayState}
                overlayPag={overlayPag}
                changeOverlayPag={setOverlayPag}
                formValues={formValues}
                setFormValues={setFormValues}
                user={user.USER}
                motivos={motivos}
            />

        </>
    )



}

export default FolhaPag;