import { useContext, useState } from "react";
import { replace, useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth/AuthProvider";
import LoadingScreen from "../Componentes/LoadingScreen";

import { HiOutlineMail } from "react-icons/hi";
import { FiLock } from "react-icons/fi";
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";


import style from './NewLogin.module.css';

const NewLogin = () => {

    const navigate = useNavigate();
    const { loading, login } = useContext( AuthContext )

    const [ passView, setPassView ] = useState( false );
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");

    const showPass = () => {

        setPassView( !passView );

    }

    const handleSubmit = async ( e ) => {

        e.preventDefault();

        try{

            await login( email, password );
            navigate( "/", replace )

        }catch( error ){

            console.log( 'nao vai navegar' );
            console.error( error );

        }

    }

    const navigateRegister = () => {

        navigate("/Register")

    }

    if( loading ){

        return( <LoadingScreen /> )

    }

    return(
        <div className={ style.background } >
            <div className={ style.container } >
                <div className={ style.imgLeftContainer } >
                    <div className={ style.imgContainer } >
                        <img src={'/Images/PREPARO_DE_MADEIRA.jpg'}  alt='fundoPalmont' />
                    </div>
                </div>
                <div className={ style.imgRightContainer } >
                    <div className={ style.loginContainer } >
                        <img className={ style.logoPalmont } src='/Images/Logo.png' alt='palmontLogo' />
                        <form>
                            <div className={ style.inputData } >
                                <div className={ style.iconInput } >
                                    <HiOutlineMail />
                                </div>
                                <input
                                    type='email'
                                    placeholder='E-mail'
                                    value={ email }
                                    onChange={ ( e ) => setEmail( e.target.value ) }
                                />
                            </div>
                            <div className={ style.inputData } >
                                <div className={ style.iconInput } >
                                    <FiLock />
                                </div>
                                <input
                                    type={ passView ? 'text' : 'password' }
                                    placeholder='Senha'
                                    value={ password }
                                    onChange={ ( e ) => setPassword( e.target.value ) }
                                />
                                <div
                                    className={ style.showPass }
                                    onClick={ showPass }
                                >
                                    { passView ? ( <IoEye /> ) : ( <IoMdEyeOff /> ) }
                                </div>
                            </div>
                            <button className={ style.loginBtn } onClick={ handleSubmit }>LOGIN</button>
                            <button className={ style.registerBtn } onClick={ navigateRegister } type="button" >REGISTRAR</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default NewLogin;