import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState( false );

    const checkAuth = async () => {

        setLoading( true );

        try{

            const { data } = await axios.get('https://sistemaintegrado.palmont.com.br/Painel/API/Auth/', { withCredentials: true });
            //const { data } = await axios.get('http://localhost/Painel/API/Auth/', { withCredentials: true });

            if( data && Object.keys(data).length > 0 ){

                setUser( data );

            }

            console.log( data );

        }catch( error ){

            console.log( error );
            setUser( null );

        }finally{

            setLoading( false );

        }

    }

    useEffect(() => {

        checkAuth();

    }, [])

    const login = async ( email, senha ) => {

        setLoading( true );

        const formData = new FormData();
        formData.append('email', email );
        formData.append('senha', senha );

        try{

            await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/Login/', formData, { withCredentials: true });
            //await axios.post('http://localhost/Painel/API/Login/', formData, { withCredentials: true });
            await checkAuth();

        }catch( error ){

            throw error;

        }finally{

            setLoading( false );

        }

    }

    const logout = async () => {

        try{

            await axios.post('https://sistemaintegrado.palmont.com.br/Painel/API/Logout/', {} ,{ withCredentials: true })
            Cookies.remove('token');
            setUser( null );

        }catch( error ){

            console.log( error );

        }

    }

    return (
        <AuthContext.Provider value={{ user, loading, login, logout, checkAuth }}>
            {children}
        </AuthContext.Provider>
    );
};